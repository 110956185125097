import {
  AuthProvider,
  Auth as FirebaseAuth,
  User,
  UserCredential,
  getAdditionalUserInfo,
  updateProfile,
} from '@firebase/auth';
import { getApp } from 'firebase/app';
import {
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
  signInWithPopup,
} from 'firebase/auth';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { isEmpty } from 'react-redux-firebase';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  profileSelector,
  startLoadingApp,
  stopLoadingApp,
  updateProfile as updateProfileAction,
} from '@hints/client';
import { ReactComponent as AppleIcon } from '@hints/shared/icons/auth/apple.svg';
import { ReactComponent as GoogleIcon } from '@hints/shared/icons/auth/google.svg';
import { ReactComponent as HintsIcon } from '@hints/shared/icons/auth/hints_logo_app.svg';

import { ampli } from '../../ampli';
import { useAppDispatch, useAppSelector, useHttpsCallable } from '../hooks';
import { useQueryParam } from '../hooks/useQueryParam';
import { getUrlWithQueryParams } from '../utils/getUrlWithQueryParams';

const PRIVACY_URL = 'https://hints.so/privacy';
const TERMS_URL = 'https://hints.so/terms';

const usePreviousLocation = () => {
  const location = useLocation();
  return (location.state as { from: Location } | undefined)?.from;
};

export const Auth = ({ isSignIn }: { isSignIn?: boolean }) => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const from = usePreviousLocation();
  const targetPath = from ? `${from.pathname}${from.search}${from.hash}` : '/';
  const emailFromQueryParam = useQueryParam('email');
  const [emailAddress, setEmail] = useState(
    emailFromQueryParam || localStorage?.getItem('emailForSignIn') || '',
  );
  const currentUser = useAppSelector(profileSelector);
  const [createHubspotContact] = useHttpsCallable('createHubspotContact');
  const [sendSignInLinkToEmail] = useHttpsCallable('sendSignInLinkToEmail');

  const updateUserProfile = async (user: User) => {
    try {
      const { providerData, uid: id } = user;
      if (providerData?.length) {
        const { displayName, email, photoURL } = providerData[0];
        await dispatch(
          updateProfileAction({
            id,
            email,
            name: displayName,
            toltReferral: (window as any).tolt_referral ?? null,
          }),
        );
        await updateProfile(user, {
          displayName,
          photoURL,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const performLogin = async (
    getUserCredential: (auth: FirebaseAuth) => Promise<UserCredential>,
    providerName: 'apple' | 'google',
  ) => {
    const auth = getAuth(getApp());
    dispatch(startLoadingApp());
    try {
      const userCredential = await getUserCredential(auth);
      const { user } = userCredential;
      await updateUserProfile(user);
      const additionalUserInfo = getAdditionalUserInfo(userCredential);
      if (additionalUserInfo?.isNewUser) {
        await createHubspotContact({
          userId: user.uid,
          email: user?.email,
          name: user?.displayName,
          source: 'app.productivity-ai.net',
        });
        ampli.signup({ auth_with: providerName, product: 'productivity' });
        if ((window as any).tolt && user.email) {
          (window as any).tolt.signup(user.email);
        }
      } else {
        ampli.login({ auth_with: providerName, product: 'productivity' });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const loginWithPopup = async (
    providerName: 'apple' | 'google',
    provider: AuthProvider,
  ) => {
    await performLogin((auth) => signInWithPopup(auth, provider), providerName);
  };

  const onApplePress = async () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    await loginWithPopup('apple', provider);
  };

  const onEmailPress = async () => {
    if (!emailAddress) {
      toast.error('Please enter your email address', { duration: 1500 });
      inputRef.current?.focus();
      return;
    }
    if (
      !String(emailAddress)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
    ) {
      toast.error('Please enter valid email address', { duration: 1500 });
      inputRef.current?.focus();
      return;
    }
    dispatch(startLoadingApp());
    try {
      localStorage?.setItem('emailForSignIn', emailAddress);
      await sendSignInLinkToEmail({
        email: emailAddress,
        redirectUrl: getUrlWithQueryParams(
          `${window.location.origin}/finishSignUp${window.location.search}`,
          {
            email: emailAddress,
            from: targetPath,
          },
        ),
      });
      navigate('/emailSent');
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(stopLoadingApp());
    }
  };

  const onGooglePress = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    await loginWithPopup('google', provider);
  };

  useEffect(() => {
    if (currentUser && !isEmpty(currentUser)) {
      dispatch(stopLoadingApp());
      navigate(targetPath, { replace: true });
    }
  }, [currentUser]);

  return (
    <div className="flex max-h-full h-full flex-1 flex-row-reverse overflow-hidden">
      <div className="flex flex-col justify-center px-4 py-12 sm:px-6 lg:px-20 xl:px-24 flex-1">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <HintsIcon className="w-10" />
            <h2 className="mt-8 text-2xl font-medium leading-9 text-gray-900">
              {isSignIn ? 'Sign in to' : 'Create'} your Hints AI account
            </h2>
            {isSignIn ? null : (
              <p className="mt-2 text-sm leading-6 text-gray-500">
                Get up to 50 trial integration usages
              </p>
            )}
          </div>

          <div className="mt-6">
            <div>
              <form
                action="#"
                method="POST"
                className="space-y-6"
                onSubmit={(e) => {
                  e.preventDefault();
                  onEmailPress();
                }}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={emailAddress}
                      onChange={(event) => setEmail(event.target.value)}
                      ref={inputRef}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center" />

                  <div className="text-sm leading-6">
                    <span className="mr-2">
                      {isSignIn
                        ? 'Still no account?'
                        : 'Already have an account?'}
                    </span>
                    <Link
                      to={isSignIn ? '/auth' : '/sign-in'}
                      className="font-semibold text-indigo-600 hover:text-indigo-500"
                    >
                      {isSignIn ? 'Sign Up' : 'Sign in'}
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-gray-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition-all duration-300 ease-in-out"
                  >
                    Get started
                  </button>
                </div>
              </form>
            </div>

            <div className="mt-6">
              <div className="relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-200" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 text-gray-900">or</span>
                </div>
              </div>

              <div className="mt-6 w-full flex flex-col items-center justify-between space-y-2">
                <button
                  onClick={onGooglePress}
                  type="button"
                  className="flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-gray-700 bg-white hover:bg-gray-100 border border-solid border-gray-200 transition-all duration-300 ease-in-out"
                >
                  <GoogleIcon className="w-5 h-5" />
                  <span className="text-sm font-semibold leading-6">
                    Start with Google
                  </span>
                </button>

                {isSignIn && (
                  <button
                    onClick={onApplePress}
                    type="button"
                    className="flex w-full items-center justify-center gap-3 rounded-md px-3 py-2 text-gray-700 bg-white hover:bg-gray-100 border border-solid border-gray-200 transition-all duration-300 ease-in-out"
                  >
                    <AppleIcon className="w-5 h-5" />
                    <span className="text-sm font-semibold leading-6">
                      Start with Apple
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="mt-8">
            <p className="text-xs text-center text-gray-500">
              By clicking “Start with Apple” or ““Start with Google” above, you
              acknowledge that you have read and agree to Hints
              <a
                className="underline cursor-pointer text-neutral-400 hover:text-neutral-600 after:content-['↗'] transition duration-300 ease-in-out"
                href={TERMS_URL}
                target="_blank"
                rel="noreferrer"
              >
                Terms of service
              </a>{' '}
              and{' '}
              <a
                className="underline cursor-pointer text-neutral-400 hover:text-neutral-600 after:content-['↗'] transition duration-300 ease-in-out"
                href={PRIVACY_URL}
                target="_blank"
                rel="noreferrer"
              >
                Privacy policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
