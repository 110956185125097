import dedent from 'dedent-js';

import { BotCommand, IntegrationSource } from '@hints/types';

import { OnboardingableService, SupportLinkCode } from './types';

export const supportLinks: Record<SupportLinkCode, string> = {
  email: 'ask@hints.so',
  intercom: 'i.hints.so/support',
  telegram: 'https://i.hints.so/support',
  whatsapp: 'https://i.hints.so/support',
};

export const botLinks = {
  whatsapp: 'wa.me/19412031424',
  whatsappGroups: 'wa.me/14067809666',
};

export const getSupportLinkBySource = (source: IntegrationSource) => {
  let code: SupportLinkCode | string = source;
  if (source === 'whatsapp-group') {
    code = 'whatsapp';
  }
  return (supportLinks as Record<string, string>)[code] || supportLinks.email;
};

export const getSupportLinks = (supportLinkCode?: SupportLinkCode) => dedent`\n
  Questions? ${
    (supportLinkCode && supportLinks[supportLinkCode]) || supportLinks.email
  }
  Or ask me for /${BotCommand.help}
`;

export const guideLinks: Record<OnboardingableService, string> = {
  clickup: 'get.hints.so/clickup_guide',
  'google-calendar': 'get.hints.so/calendar_guide',
  hubspot: 'get.hints.so/hubspot_guide',
  jira: 'get.hints.so/jira_guide',
  notion: 'get.hints.so/notion_guide',
  obsidian: 'get.hints.so/obsidian_guide',
  pipedrive: 'get.hints.so/pipedrive_guide',
  salesforce: 'get.hints.so/salesforce_guide',
  trello: 'get.hints.so/trello_guide',
};

export const learningCenterLink = 'get.hints.so/iFAQ';
