import localStorage from 'redux-persist/lib/storage';

import { RootState, createStore } from '@hints/client';

import { firebaseConfig } from './configs/firebase';

// eslint-disable-next-line
export interface AppRootState extends RootState {}

export const { store, persistor } = createStore<AppRootState>(
  localStorage,
  firebaseConfig,
  () => {},
);
