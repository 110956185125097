import { createSelector } from '@reduxjs/toolkit';

import { SubscriptionWithId } from '@hints/types';

import { RootState } from '../types';

export const subscriptionsDataSelector = createSelector(
  (s: RootState) => s.firestore?.data?.userSubscriptions ?? [],
  (s) => s
);

export const subscriptionsArraySelector = createSelector(
  (s: RootState) => s.firestore?.ordered?.userSubscriptions ?? [],
  (s) => s
);

export const subscriptionSelector = createSelector(
  (s: RootState) => s.firestore?.ordered?.userSubscriptions ?? [],
  (s): SubscriptionWithId | null => s?.[0] ?? null
);
