// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { NODE_ENV } = process.env;

export const isProduction = NODE_ENV === 'production';

export * from './commands';
export * from './crypto';
export * from './date';
export * from './firestore';
export * from './media';
export * from './note';
export * from './object';
export * from './text';
export * from './url';
export * from './vector';
