export const userStageValues = {
  trial: 'trial',
  buyer: 'buyer',
  'active-buyer': 'active-buyer',
  churn: 'churn',
  research: 'research',
};

export type HubspotUserStage = keyof typeof userStageValues;

export type HubSpotContactUserStageProperty = {
  property: 'user_stage';
  value: HubspotUserStage;
};

export type HubSpotContactIntegration =
  | 'hubspot'
  | 'pipedrive'
  | 'google-calendar'
  | 'salesforce'
  | 'trello'
  | 'jira'
  | 'clickup'
  | 'obsidian'
  | 'notion';

export type HubSpotContactIntegrationsProperty = {
  property: 'integrations';
  value: HubSpotContactIntegration;
};

type HubSpotContactAmplitudeIdProperty = {
  property: 'amplitude_id';
  value: string;
};

type HubSpotContactIntegrationUsedCountProperty = {
  property: 'integration_used_count';
  value: number;
};

export type HubSpotContactSourceProperty = {
  property: 'source';
  value:
    | 'app.hintsflow.ai'
    | 'i.hints.so'
    | 'app.crmchat.ai'
    | 'app.productivity-ai.net';
};

export type HubSpotContactProperty =
  | HubSpotContactUserStageProperty
  | HubSpotContactIntegrationsProperty
  | HubSpotContactAmplitudeIdProperty
  | HubSpotContactIntegrationUsedCountProperty
  | HubSpotContactSourceProperty;
