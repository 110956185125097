import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export const useEffectExceptOnMount = (
  effect: EffectCallback,
  dependencies?: DependencyList,
) => {
  const mounted = useRef(false);
  useEffect(() => {
    if (mounted.current) {
      const unmount = effect();
      return () => unmount && unmount();
    }
    mounted.current = true;
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  // Reset on unmount for the next mount.
  useEffect(
    () => () => {
      mounted.current = false;
    },
    [],
  );
};

export default useEffectExceptOnMount;
