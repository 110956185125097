import { getAuth } from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';

import { User } from '@hints/types';
import { removeUndefinedDeep } from '@hints/utils';

export const getUserId = () => getAuth().currentUser?.uid;

export const getUser = async (id: string): Promise<User | undefined> => {
  const userID = id || getUserId();
  try {
    const userSnapshot = await getDoc(doc(getFirestore(), `users/${userID}`));
    if (userSnapshot.exists()) {
      return { ...userSnapshot.data(), id: userSnapshot.id } as User;
    }
    return undefined;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const patchUser = async (data: any) => {
  const userId = getUserId();
  if (!userId) {
    return;
  }
  const userRef = doc(getFirestore(), `users/${userId}`);

  await setDoc(userRef, removeUndefinedDeep(data), { merge: true });
};

export const initUser = async (user: Partial<User>) => {
  const patchData: Partial<User> = user;

  if (Object.keys(patchData).length > 0) {
    await patchUser(patchData);
  }
};
