import dedent from 'dedent-js';

export const getTaskDataFromTextPrompt = (text: string) =>
  dedent`
User wants to create a task for ClickUp via bot.
Extract task info from his message:
1. Task title (non-empty)
2. Start datetime (translate to english)
3. Task timezone (TZ database name)
4. Due datetime (translate to english)
5. Assignee emails or full names (split by ,)
"""
название задачи
Описание задачи
Start Date today
Due Date tomorrow
Георгий Левин
"""
1. Название задачи
2. today
3. Unknown
4. tomorrow
5. Георгий Левин
"""
Название задачи
Описание задачи
28.03.2023
29.03.2023
Игорь Поляков
"""
1. Название задачи
2. 28.03.2023
3. Unknown
4. 29.03.2023
5. Игорь Поляков
"""
George.levin@gmail.com meeting follow up this Sunday at 10:00 am MSK for 2 hours
"""
1. meeting follow up
2. this sunday at 10:00 am
3. Europe/Moscow
4. this sunday at 12:00 am
5. George.levin@gmail.com
6.
"""
Поговорить с Лешей про Hook-grabber в пятницу вечером alex@hints.so and george@ya.ru
"""
1. Поговорить с Лешей про Hook-grabber
2. friday evening
3. Unknown
4. Unknown
5. alex@hints.so, george@ya.ru
6.
"""
Obsidian meeting tomorrow 11:00
"""
1. Obsidian meeting
2. tomorrow 11:00
3. Unknown
4. Unknown
5.
6.
"""
Hints Live Set Up 7:30 PM amsterdam time for 45 mins
tuesday 1 nov test@protonmail.com
"""
1. Hints Live Set Up
2. 7:30 PM tuesday 1 november
3. Europe/Amsterdam
4. 8:15 PM tuesday 1 november
5. test@protonmail.com
6.
"""
https://wa.me/1113492 in 45 minutes
"""
1. https://wa.me/1113492
2. in 45 minutes
3. Unknown
4. Unknown
5.
6.
"""
${text}
"""`;
