import { getFunctions } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { NavLink, useNavigate } from 'react-router-dom';

import SlackIcon from '@hints/shared/icons/integrations/Slack.svg';

import { LoaderIcon } from '../components';
import useQuery from '../hooks/useQuery';

export const SlackAuth = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [isCompleted, setIsCompleted] = useState(false);
  const [authSlack, isLoading, error] = useHttpsCallable(
    getFunctions(undefined, 'europe-west3'),
    'authorizeSlack',
  );

  useEffect(() => {
    const authCode = query.get('code');
    const token = decodeURIComponent(query.get('e') ?? '');
    if (!authCode && !token) {
      navigate('/');
      return;
    }
    if (isCompleted || isLoading) return;

    authSlack({ code: authCode, e: token })
      .catch(console.error)
      .finally(() => setIsCompleted(true));
  }, []);

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <LoaderIcon />
      </div>
    );
  }

  return (
    <div
      className={`w-screen h-screen flex items-center justify-center lg:justify-between px-12 md:px-24 bg-cover 'bg-vibrant dark:bg-none dark:bg-neutral-900'`}
    >
      <div className="w-full h-full items-center justify-center flex flex-col">
        <div className="w-full max-w-xs">
          <div className="space-y-6 items-center justify-center select-none	flex flex-col">
            <img className={`w-14 h-auto`} alt="icon" src={SlackIcon} />;
            <div>
              <h1 className="text-neutral-900 dark:text-neutral-100 font-bold text-3xl text-center mb-4 select-none	">
                {error
                  ? 'Something went wrong'
                  : 'Slack successfully integrated'}
              </h1>
              {!error ? (
                <p className="text-md text-center text-neutral-900 dark:text-neutral-300">
                  Use Hints bot to save ideas or opportunities
                </p>
              ) : null}
            </div>
            <div className="items-center flex space-y-4 flex-col">
              <NavLink
                to="/"
                type="button"
                className="bg-neutral-900 hover:bg-neutral-700 dark:bg-neutral-600 rounded-lg flex py-3 px-6 justify-center items-center w-64 text-white transition duration-300 ease-in-out"
              >
                Back to Hints App
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
