import {
  ReducersMapObject,
  combineReducers,
} from '@reduxjs/toolkit';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import { PersistConfig, Storage, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { RootState } from '../types';
import { isAppLoading } from './loading';

export const makeRootReducer = <S extends RootState>(
  storage: Storage,
  asyncReducers?: Partial<ReducersMapObject<S>>
) => {
  const firestorePersistConfig: PersistConfig<S> = {
    key: 'firestore',
    storage,
    whitelist: ['data', 'ordered'],
    stateReconciler: autoMergeLevel2,
  };
  const firebasePersistConfig = {
    key: 'firebase',
    storage,
    whitelist: ['auth', 'profile'],
  };
  const appPersistConfig = {
    key: 'app',
    storage,
    blacklist: ['firestore', 'firebase'],
  };
  const reducer = combineReducers<S>({
    isAppLoading,
    firestore: persistReducer(firestorePersistConfig, firestoreReducer),
    firebase: persistReducer(firebasePersistConfig, firebaseReducer),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(asyncReducers as any),
  });
  return persistReducer(appPersistConfig, reducer);
};

export default makeRootReducer;
