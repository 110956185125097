// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import {
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
} from 'firebase/auth';
import { join, split } from 'ramda';
import {
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import * as analytics from '../../api/analytics';
import { initUser } from '../../api/user';
import logo from '../../assets/logo.svg';
import { useQueryParam } from '../hooks/useQueryParam';

const AppLoader = () => (
  <div className="flex justify-center items-center w-full h-full md:bg-purple-50">
    <img src={logo} alt="Hints" className="w-20 h-20" />
  </div>
);

const getQueryStringWithoutParam = (
  paramName: string,
  queryString: string,
): string =>
  queryString
    .slice(1)
    .split('&')
    .map(split('='))
    .filter((a) => a[0] !== paramName)
    .map(join('='))
    .join('&');

export const RequireAuth: FC<PropsWithChildren> = ({ children }) => {
  const auth = getAuth();
  const location = useLocation();
  const emailFromQueryParam = useQueryParam('email');

  if (emailFromQueryParam) {
    localStorage?.setItem('emailForSignIn', emailFromQueryParam);
  }
  if (!auth.currentUser?.uid) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
  return children as any;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const auth = getAuth();
  const location = useLocation();

  const navigate = useNavigate();
  const [authorized, setAuthorized] = useState<boolean | null>(null);
  const jwtToken = useQueryParam('jwtToken');
  const [nextUrl, setNextUrl] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (usr) => {
      if (usr) {
        const data: any = {
          id: usr.uid,
          email: usr.email,
          isAnonymous: usr.isAnonymous,
          name: usr.displayName,
          timezone: new window.Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        await initUser(data);
        analytics.identify(usr.uid);
        Sentry.setUser({ id: usr.uid, email: usr.email ?? undefined });
        setAuthorized(true);
        if (nextUrl) {
          setNextUrl(null);
          navigate(nextUrl);
        }
      } else {
        setAuthorized(false);
      }
    });
    return () => unsubscribe();
  }, [auth, nextUrl]);

  useEffect(() => {
    if (jwtToken) {
      if (!auth?.currentUser?.uid) {
        const queryString = getQueryStringWithoutParam(
          'jwtToken',
          location.search,
        );
        setNextUrl(
          `${location.pathname}${queryString ? `?${queryString}` : ''}`,
        );
        signInWithCustomToken(getAuth(), jwtToken)
          .then((u) => {})
          .catch((e) => {
            console.error(e);
            navigate('/auth');
          });
      } else {
        const queryString = getQueryStringWithoutParam(
          'jwtToken',
          location.search,
        );
        navigate(`${location.pathname}${queryString ? `?${queryString}` : ''}`);
      }
    }
  }, [jwtToken]);

  if (authorized === null || jwtToken) {
    return <AppLoader />;
  }

  return children as ReactElement;
};
