import { useEffect } from 'react';

import { ReactComponent as QuestionMark } from '@hints/shared/icons/question-mark-cr-fr.2.svg';

import { ampli } from '../../ampli';
import { useIntercom } from '../contexts/IntercomContext';

export const Intercom = ({
  className,
  forceOpen,
}: {
  className?: string;
  forceOpen?: boolean;
}) => {
  const [isShow, setIsShow] = useIntercom();
  const showHide = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Intercom(isShow ? 'hide' : 'show');
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setIsShow(!isShow);
  };

  useEffect(() => {
    if (forceOpen) {
      showHide();
    }
  }, [forceOpen]);

  if (isShow) {
    return null;
  }
  return (
    <button
      type="button"
      className={`fixed z-10 right-6 bottom-10 md:bottom-5 rounded-full ${className}`}
      onClick={showHide}
    >
      <QuestionMark width={32} height={32} />
    </button>
  );
};
