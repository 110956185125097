export interface ProgressBarProps {
  progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
}: ProgressBarProps) => (
  <div className="flex items-center w-full h-2.5 rounded-lg bg-gray-100 px-1">
    <div
      style={{ width: `${progress}%` }}
      className="h-1.5 rounded-lg bg-gray-800"
    />
  </div>
);

export default ProgressBar;
