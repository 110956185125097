import { Action, getType } from '@reduxjs/toolkit';
import { startLoadingApp, stopLoadingApp } from '../actions';

export const isAppLoading = (state: boolean, action: Action) => {
  switch (action.type) {
    case getType(startLoadingApp):
      return true;

    case getType(stopLoadingApp):
      return false;

    default:
      return state || false;
  }
};
