import { useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

import successImage from '../../assets/success_payment.png';
import { ResponsiveScreen } from '../components';
import { Button } from '../components';

export const SuccessPage = () => {
  const navigate = useNavigate();

  const [isRunning, setIsRunning] = useState(true);
  setTimeout(() => {
    setIsRunning(false);
  }, 3000);

  return (
    <ResponsiveScreen
      classname={'bg-[#D8CEFF] md:bg-[#D8CEFF]'}
      noVerticalPadding
    >
      <div className="relative h-full w-full py-5">
        <div className="h-full flex flex-col justify-between">
          <div className="flex flex-col items-center h-full overflow-y-auto mb-6">
            <div className="my-5">
              <img src={successImage} alt="Success" className="h-60" />
            </div>
            <h3 className="mt-4 text-2xl font-bold">
              Thank you for subscribing!
            </h3>
            <p className="mt-4 text-center">
              You have successfully activated Hints AI Assistant
            </p>
          </div>
          <Button
            isFull
            size="big"
            type="primary"
            isRounded
            text={`Back to Home`}
            onClick={() => {
              navigate('/');
            }}
          />
          <Confetti
            numberOfPieces={isRunning ? 100 : 0}
            width={window.innerWidth > 370 ? 370 : window.innerWidth}
            height={window.innerHeight > 750 ? 750 : window.innerHeight}
          />
        </div>
      </div>
    </ResponsiveScreen>
  );
};
