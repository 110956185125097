import { ReducersMapObject, configureStore } from '@reduxjs/toolkit';
import { getFirebase } from 'react-redux-firebase';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import { Storage, persistStore } from 'redux-persist';
import { makeRootReducer } from '../reducers';
import { RootState, ThunkExtra } from '../types';

export const createStore = <S extends RootState>(
  storage: Storage,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  firebaseConfig: any,
  trackAnalytics: ThunkExtra['trackAnalytics'],
  asyncReducers?: Partial<ReducersMapObject<S>>
) => {
  const extraArgument: ThunkExtra = {
    getFirestore,
    getFirebase,
    trackAnalytics,
  };

  const store = configureStore({
    reducer: makeRootReducer<S>(storage, asyncReducers),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument,
        },
      }),
    enhancers: [reduxFirestore(firebaseConfig)],
  });
  const persistor = persistStore(store);
  return { store, persistor };
};

export const reduxFirebaseConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  attachAuthIsReady: true,
};
