import { FC, PropsWithChildren } from 'react';

import { Button, ButtonProps } from '../../../components';

export const BackButton = (props: ButtonProps) => (
  <div className="w-1/3">
    <Button isFull size="big" type="inline" text="Back" isRounded {...props} />
  </div>
);
export const NextButton = (props: ButtonProps) => (
  <Button isFull size="big" type="primary" text="Next" isRounded {...props} />
);

export const NavigationButtons: FC<PropsWithChildren<unknown>> = ({
  children,
}) => <div className="flex">{children}</div>;
