import { flatten, join, map, pipe, uniq } from 'ramda';

import {
  BotCommand,
  BotCommandType,
  Integration,
  actionsByDestination,
} from '@hints/types';

export const getAvailableSubcommands = pipe(
  map((integration: Integration) => integration.destination as string),
  uniq,
  map((service: string) => actionsByDestination[service]),
  flatten,
  uniq,
  map((action) => `- ${action}`),
  join('\n'),
);

export const isCommandListedInCommands = (
  command: BotCommandType,
  integrations: Integration[],
) =>
  [
    ...Object.values(BotCommand),
    ...getAvailableSubcommands(integrations),
  ].includes(command);

const billableCommands: BotCommandType[] = [
  BotCommand.flow,
  BotCommand.sum,
  BotCommand.paint,
];

export const isBillableCommand = (command: BotCommandType | null): boolean =>
  !!command && billableCommands.includes(command);
