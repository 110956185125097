import { useNavigate } from 'react-router-dom';

import { profileSelector } from '@hints/client';

import logo from '../../assets/logo-circle.svg';
import { useAppSelector } from '../hooks';
import { GoBackButton } from './GoBackButton';
import { ProgressBar } from './ProgressBar';

interface HeaderProps {
  title?: string;
  routeToNavigate?: string | number;
  progress?: number | undefined;
  isBackDisabled?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  title,
  routeToNavigate,
  progress,
  isBackDisabled = false,
}: HeaderProps) => {
  const user = useAppSelector(profileSelector);
  const navigate = useNavigate();

  return (
    <div
      className={`w-full py-3 flex justify-between items-center${
        progress !== undefined ? ' py-4 space-x-10' : ''
      }`}
    >
      <GoBackButton
        routeToNavigate={routeToNavigate}
        isDisabled={isBackDisabled}
      />
      {progress ? (
        <ProgressBar progress={progress} />
      ) : (
        <>
          <h2 className="text-neutral-800 font-semibold font-sans w-full overflow-hidden text-ellipsis text-center">
            {title}
          </h2>
          <img
            src={logo}
            alt="Hints"
            onClick={() => navigate('/')}
            className="w-7 h-7 rounded-full bg-gray-100 text-[0px] ml-4 cursor-pointer"
          />
        </>
      )}
    </div>
  );
};

export default Header;
