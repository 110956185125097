import dedent from 'dedent-js';

import { BotCommand } from '@hints/types';

import { supportLinks } from '../common/links';
import { OnboardingResponse } from '../types';

export default {
  getBotOnboardingText: (redirectUrl?: string) => dedent`
    💡 Hints Assistant is here!
    ${redirectUrl ? `\nFinish your flow setup [here](${redirectUrl})\n` : ''}
    ➕ *To add a new lead to your Notion database* — send me content for all relevant properties in a single request with #notion. *Example*:

    \`Send to Notion: Willie Yao, title – Head of Infrastructure, company – Notion, estimated value is 2000, linkedin.com/in/willieyao, team@makenotion.com #notion\`

    ✔️ *To update existing leads* — use natural language such as \\[*change*], \\[*update*] or \\[*add to*] in your request with #notion. *Example*:

    \`Change the stage of Willie Yao to PROSPECT #notion\`


    _P.S. Don’t hesitate to get in touch with my support team ${
      supportLinks.telegram
    } or use /${BotCommand.help} for more commands_
  `,
} as OnboardingResponse;
