import { updateDoc } from '@firebase/firestore';
import { doc, getFirestore } from 'firebase/firestore';
import { useEffect, useState } from 'react';

import { profileSelector } from '@hints/client';

import { useAppSelector } from '../../hooks';
import { classNames } from '../../utils/classNames';
import { notify } from '../../utils/notifications';

const SettingsRecorder = () => {
  const user = useAppSelector(profileSelector);
  const [apiKey, setApiKey] = useState(user.hintsApiKey || '');
  const getUserId = () => user?.id;
  const patchUser = async (data: any) => {
    const userId = getUserId();
    if (!userId) {
      return;
    }
    const userRef = doc(getFirestore(), `users/${userId}`);

    await updateDoc(userRef, data, { merge: true });
  };

  useEffect(() => {
    if (user.hintsApiKey) {
      setApiKey(user.hintsApiKey);
    }
  }, [user]);

  const generateApiKey = async () => {
    const newApiKey = crypto.randomUUID();
    setApiKey(newApiKey);
    await patchUser({ hintsApiKey: newApiKey });
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(apiKey);
      notify('API Key copied', 'success');
    } catch (err) {
      console.error('Failed to copy API key: ', err);
    }
  };

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Security settings
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">Manage API keys</p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Generate API key
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <input
                type="input"
                name="api"
                className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="XXXX-XXXX-XXXX"
                value={apiKey}
                onClick={copyToClipboard}
                readOnly
              />
              <button
                type="button"
                className={classNames(
                  'font-semibold text-indigo-600 hover:text-indigo-500',
                  apiKey && 'cursor-not-allowed opacity-50',
                )}
                onClick={generateApiKey}
                disabled={!!apiKey}
              >
                Generate
              </button>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default SettingsRecorder;
