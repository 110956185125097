export type BotCommandViaSlash =
  | 'connect' // connect_api_integration
  | 'feedback'
  | 'help'
  | 'paint'
  | 'sum' // summarize
  | 'flow' // use_api_integration
  | 'voice_language';

export type BotCommandType =
  | BotCommandViaSlash
  | 'integration'
  | 'help2'
  | 'summarize';

export const BotCommand: Record<BotCommandType, BotCommandType> = {
  connect: 'connect',
  feedback: 'feedback',
  help: 'help',
  paint: 'paint',
  sum: 'sum',
  flow: 'flow',
  integration: 'integration',
  help2: 'help2',
  summarize: 'summarize',
  voice_language: 'voice_language',
};

export const availableCommands: BotCommandType[] = [
  BotCommand.help,
  BotCommand.connect,
  BotCommand.feedback,
  BotCommand.paint,
  BotCommand.sum,
  BotCommand.flow,
  BotCommand.voice_language,
];

export const hasCommand = (text: string, commandName: string) => {
  const lowerCasedText = text.toLowerCase().trim();
  return (
    lowerCasedText.endsWith(`/${commandName}`) ||
    lowerCasedText.includes(`/${commandName} `) ||
    lowerCasedText.includes(`/${commandName}@`)
  );
};

export const isAvailableCommandUsed = (text: string) =>
  availableCommands.reduce(
    (result, command) => result || hasCommand(text, command),
    false,
  );

export const getCommandUsedInText = (text: string) =>
  availableCommands.find((c) => hasCommand(text, c)) as BotCommandType;

export const botCommandToDescription = {
  [BotCommand.help]: 'gives basic instructions',
  [BotCommand.flow]:
    'allows you to easily send or retrieve information with connected services and utilize integrations',
  [BotCommand.paint]: 'gives access to my image generation capabilities',
  [BotCommand.sum]: 'generates summaries from provided text or URL',
  [BotCommand.connect]: 'links your tools and connects new integrations',
  [BotCommand.feedback]:
    'sends a bug-report, feature-request or gratitude to dev team',
  [BotCommand.voice_language]: `change voice transcription language`,
} as Record<BotCommandViaSlash, string>;

export type CommandResponse = {
  command: BotCommandType | null;
  property?: string;
};

export const actionsByDestination: Record<string, string[] | undefined> = {
  'google-calendar': ['create_event', 'get_events'],
  obsidian: ['create_note'],
  trello: ['create_card'],
  jira: ['create_issue'],
  clickup: ['create_task', 'remove_task'],
  notion: ['create_page', 'update_page'],
  hubspot: [
    'create_deal',
    'update_deal',
    'add_note',
    'get_last_contact',
    'get_tasks',
  ],
  pipedrive: [
    'create_deal',
    'update_deal',
    'add_note',
    'get_last_contact',
    'get_tasks',
  ],
  salesforce: [
    'create_deal',
    'update_deal',
    'add_note',
    'get_last_contact',
    'get_tasks',
  ],
};

export type CommandResult = {
  text: string;
  isTemporary?: boolean;
  isForcedReply?: boolean;
} | null;

export type IntegrationCommandType = 'connect' | 'remove' | 'edit' | 'run';

export const IntegrationCommand: Record<
  IntegrationCommandType,
  IntegrationCommandType
> = {
  connect: 'connect',
  remove: 'remove',
  edit: 'edit',
  run: 'run',
};
