import dedent from "dedent-js";
import { OnboardingResponseParams, OnboardingableService } from "./types";

export default <Record<OnboardingableService, OnboardingResponseParams>>{
  clickup: {
    serviceName: 'ClickUp',
    serviceCode: 'clickup',
    hashtagName: 'clickup',
    managableEntity: 'workspace',
    example: (hashtag: string) =>
      `#${hashtag} Revise Marketing Strategy for Q3 Campaign`,
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/slipbox-6f705.appspot.com/o/!onboarding%2FClickUp.png?alt=media&token=65bc3d48-f0ea-4965-8672-0742c664a137&_gl=1*1kc954e*_ga*MTM4MjA1NDU2LjE2NTcyMDA2MjI.*_ga_CW55HF8NVT*MTY4NTU0MzAzNy43LjEuMTY4NTU0MzE2MS4wLjAuMA',
  },

  'google-calendar': {
    serviceName: 'Google Calendar',
    serviceCode: 'google-calendar',
    hashtagName: 'calendar',
    managableEntity: 'schedule',
    example: (hashtag: string) =>
      `➕ Create event: Work on the investor deck tomorrow at 5 pm for 75 min

👥 Add guests: Marketing sync next monday 10am with Alex

⏰ Convert zone: Teamwide strategy session 20 April at 4PM NYC

📆 Add multiple calendars: Team sync next friday 10:00AM #work

📃 Get list of events: Send me my schedule for tomorrow`,
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/slipbox-6f705.appspot.com/o/!onboarding%2FCalendar.png?alt=media&token=0ee9ea6a-5ee5-4a89-9d55-abcd1be8f9c8&_gl=1*1hblxst*_ga*MTM4MjA1NDU2LjE2NTcyMDA2MjI.*_ga_CW55HF8NVT*MTY4NTUyNjEzOC42LjEuMTY4NTUyNjk5MS4wLjAuMA',
  },

  hubspot: {
    serviceName: 'HubSpot',
    serviceCode: 'hubspot',
    hashtagName: 'hubspot',
    managableEntity: 'CRM',
    example: (hashtag: string) =>dedent
    `HubSpot ✔️
    Try with voice 🎙️ or text: 
    "Create a new contact John Smith and link to a new company IBM."
    "Log a call with John Smith for today at 8 AM and add a note: They are waiting for the proposal."
    "Add a task to the John Smith to send proposal by tomorrow 2 PM."
`,
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/slipbox-6f705.appspot.com/o/!onboarding%2Fhubspot.png?alt=media&token=84d0b4c5-f486-47db-ae92-7db4e90f122f',
  },

  salesforce: {
    serviceName: 'Salesforce',
    serviceCode: 'salesforce',
    hashtagName: 'salesforce',
    managableEntity: 'CRM',
    example: (hashtag: string) =>dedent
    `Salesforce ✔️
    Try with voice 🎙️ or text: 
    "Create a new contact John Smith and link to a new company IBM."
    "Log a call with John Smith for today at 8 AM and add a note: They are waiting for the proposal."
    "Add a task to the John Smith to send proposal by tomorrow 2 PM."
`,
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/slipbox-6f705.appspot.com/o/!onboarding%2Fhubspot.png?alt=media&token=84d0b4c5-f486-47db-ae92-7db4e90f122f',
  },

  jira: {
    serviceName: 'Jira',
    serviceCode: 'jira',
    hashtagName: 'jira',
    managableEntity: 'board',
    example: (hashtag: string) =>
      `#${hashtag} Fix login functionality on website`,
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/slipbox-6f705.appspot.com/o/!onboarding%2FJira.png?alt=media&token=6ee77a16-2378-40d7-a5be-88ac5ef2a3b9&_gl=1*epp0g*_ga*MTM4MjA1NDU2LjE2NTcyMDA2MjI.*_ga_CW55HF8NVT*MTY4NTUyNjEzOC42LjEuMTY4NTUyNzAyNC4wLjAuMA',
  },

  notion: {
    serviceName: 'Notion',
    serviceCode: 'notion',
    hashtagName: 'notion',
    managableEntity: 'workspace',
    example: (hashtag: string) =>
      `#${hashtag} Draft blog post on productivity tips`,
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/slipbox-6f705.appspot.com/o/!onboarding%2FNotion.png?alt=media&token=4dec5ec8-5337-4409-b019-640f1a3799c1&_gl=1*1qumx2s*_ga*MTM4MjA1NDU2LjE2NTcyMDA2MjI.*_ga_CW55HF8NVT*MTY4NTUyNjEzOC42LjEuMTY4NTUyNjg2Ni4wLjAuMA',
  },

  obsidian: {
    serviceName: 'Obsidian',
    serviceCode: 'obsidian',
    hashtagName: 'obsidian',
    managableEntity: 'folder',
    example: (hashtag: string) =>
      `#${hashtag} Manually transfer all of my Apple notes to Obsidian`,
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/slipbox-6f705.appspot.com/o/!onboarding%2FObsidian.png?alt=media&token=1b8eb9aa-4fb6-4cc0-8f92-8533c83a7a47&_gl=1*r4b2is*_ga*MTM4MjA1NDU2LjE2NTcyMDA2MjI.*_ga_CW55HF8NVT*MTY4NTUyNjEzOC42LjEuMTY4NTUyNzAxMS4wLjAuMA',
  },

  pipedrive: {
    serviceName: 'Pipedrive',
    serviceCode: 'pipedrive',
    hashtagName: 'pipedrive',
    managableEntity: 'CRM',
example: (hashtag: string) =>dedent
    `Pipedrive ✔️
    Try with voice 🎙️ or text: 
    "Create a new contact John Smith and link to a new company IBM."
    "Log a call with John Smith for today at 8 AM and add a note: They are waiting for the proposal."
    "Add a task to the John Smith to send proposal by tomorrow 2 PM."
`,
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/slipbox-6f705.appspot.com/o/!onboarding%2FPipedrive.png?alt=media&token=2475c827-dbdc-4f21-900e-e8bca0ed9d33&_gl=1*jn833l*_ga*MTM4MjA1NDU2LjE2NTcyMDA2MjI.*_ga_CW55HF8NVT*MTY4NTUyNjEzOC42LjEuMTY4NTUyNzAwMS4wLjAuMA',
  },

  trello: {
    serviceName: 'Trello',
    serviceCode: 'trello',
    hashtagName: 'trello',
    managableEntity: 'board',
    example: (hashtag: string) =>
      `#${hashtag} Revise Marketing Strategy for Q3 Campaign`,
    mediaLink:
      'https://firebasestorage.googleapis.com/v0/b/slipbox-6f705.appspot.com/o/!onboarding%2FTrello.png?alt=media&token=f98e7793-d0be-4a56-9653-09c384d012b1&_gl=1*1gcvqjy*_ga*MTM4MjA1NDU2LjE2NTcyMDA2MjI.*_ga_CW55HF8NVT*MTY4NTUyNjEzOC42LjEuMTY4NTUyNzAzNC4wLjAuMA',
  },
}
