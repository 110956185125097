import firebase from 'firebase/compat/app';

import { profileSelector, updateProfile } from '@hints/client';
import SlackIcon from '@hints/shared/icons/integrations/Slack.svg';

import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { generateShortCode } from '../../../utils/generateShortCode';
import { useOAuth2AuthorizationCodeGrantPopup } from '../hooks/useOAuth2AuthorizationCodeGrantPopup';
import { InputIntegrationMetadata, IntegrationHook } from '../types';

import FieldValue = firebase.firestore.FieldValue;

export const AUTH_URL = `https://slack.com/oauth/v2/authorize?state=${generateShortCode()}&client_id=3232569249029.3696355655170&scope=app_mentions:read,channels:history,channels:read,commands,files:read,groups:history,groups:read,im:history,im:read,im:write,metadata.message:read,mpim:history,mpim:read,mpim:write,users:read,users:read.email,chat:write,chat:write.public&user_scope=channels:history,channels:read,groups:history,groups:read,im:history,im:read,mpim:history,mpim:read,users:read`;

const useSlackConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const dispatch = useAppDispatch();

  const [authSlack] = useHttpsCallable('authorizeSlack');
  const [startAuth, isLoading] = useOAuth2AuthorizationCodeGrantPopup({
    authUrl: AUTH_URL,
    onComplete: async (code, redirectUri) => {
      await authSlack({ code, redirectUri });
    },
  });

  const isConnected = !!user.slackUserIds?.length;

  const disconnect = () =>
    dispatch(
      updateProfile({
        slackUserIds: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    isConnected,
    connect: startAuth,
    disconnect,
    isLoading,
    open: () => window.open('https://slack.com', 'slack'),
    openButtonName: 'Open Slack',
  };
};

export const slack: InputIntegrationMetadata = {
  title: 'Slack',
  logoUrl: SlackIcon,
  connectorHook: useSlackConnector,
};
