import { useState } from 'react';

import { SelectOption } from '../select/select';
import { AxiosDataSource } from './AxiosDataSource';
import { HttpsCallableDataSource } from './HttpsCallableDataSource';
import { DataSource, Remote } from './remote-select';

export const useRemote = (source: DataSource): Remote => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [options, setOptions] = useState<SelectOption[] | null>(null);

  const reset = () => setOptions(null);
  const load = async () => {
    setIsLoading(true);
    try {
      const loadedOptions = await source.load();
      setOptions(loadedOptions);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setIsLoaded(true);
    }
  };
  const reload = async () => {
    reset();
    await load();
  };

  return {
    source,
    options,
    isLoading,
    isLoaded,
    load,
    reset,
    reload,
  };
};

export const useHttpsCallableRemote = (
  ...args: ConstructorParameters<typeof HttpsCallableDataSource>
) => useRemote(new HttpsCallableDataSource(...args));

export const useAxiosRemote = (
  ...args: ConstructorParameters<typeof AxiosDataSource>
) => useRemote(new AxiosDataSource(...args));
