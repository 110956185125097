import dedent from 'dedent-js';

import onboardingResponseParams from '../common/onboardingResponseParams';
import { OnboardingResponse } from '../types';

const response: OnboardingResponse = {
  getBotOnboardingText: () => dedent`
    Pipedrive ✔️
    Try with voice 🎙️ or text:
    "Create a new contact John Smith and link to a new company IBM."
    "Log a call with John Smith for today at 8 AM and add a note: They are waiting for the proposal."
    "Add a task to the John Smith to send proposal by tomorrow 2 PM."
    `,
  mediaLink: onboardingResponseParams.pipedrive.mediaLink,
};

export default response;
