import { FC, useState } from 'react';
import { animated, useSpring } from 'react-spring';

export interface ButtonProps {
  leftContent?: any;
  rightContent?: any;
  text?: string;
  onClick?: (e: any) => void;
  isFull?: boolean;
  type?: string;
  size?: string;
  disabled?: boolean;
  isRounded?: boolean;
  isHidden?: boolean;
  extraStyles?: Record<string, string>;
}

const styles = {
  default: {
    text: 'text-gray-800',
    bg: 'bg-gray-100 hover:bg-gray-200 active:bg-gray-200 border border-gray-200 hover:border-gray-300',
  },
  primary: {
    text: 'text-white',
    bg: 'bg-gray-800 hover:bg-gray-600 active:bg-gray-700',
  },
  inline: {
    text: '',
    bg: 'bg-transparent text-gray-500 hover:text-gray-900',
  },
  outline: {
    text: '',
    bg: 'bg-transparent border-2 border-gray-400 hover:border-gray-600',
  },
  danger: {
    text: 'text-red-600',
    bg: 'bg-red-100 hover:bg-red-200 active:bg-red-200 border border-red-300 hover:border-red-300',
  },
  success: {
    text: 'text-green-600',
    bg: 'bg-green-100 hover:bg-green-200 active:bg-green-200 border border-green-200 hover:border-green-300',
  },
};

const buttonSize = {
  big: 'px-6 py-4 text-base',
  default: 'px-4 py-3.5 text-base',
  small: 'py-2 px-3 text-sm',
};

export const Button: FC<ButtonProps> = ({
  leftContent,
  rightContent,
  text,
  onClick,
  isFull = false,
  size = 'default',
  type = 'default',
  disabled = false,
  isHidden = false,
  isRounded,
  extraStyles = {},
}: ButtonProps) => {
  const [pressed, setPressed] = useState(false);
  const props = useSpring({
    transform: `scale(${pressed ? 0.95 : 1})`,
    ...extraStyles,
  });
  return (
    <animated.button
      onClick={onClick}
      style={props}
      type="button"
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      className={`flex justify-center items-center rounded-lg ${
        isFull ? 'w-full' : ''
      } ${buttonSize[size as keyof typeof buttonSize]} ${
        styles[type as keyof typeof styles].bg
      } ${
        disabled
          ? 'pointer-events-none opacity-50'
          : 'cursor-pointer pointer-events-auto'
      } ${
        isHidden
          ? 'opacity-0 !pointer-events-none'
          : 'flex pointer-events-auto cursor-pointer'
      } ${
        isRounded && 'rounded-full'
      } space-x-2 transition-colors duration-300 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-black`}
    >
      {leftContent}
      {text ? (
        <span
          className={`${
            styles[type as keyof typeof styles].text
          } font-semibold leading-5`}
        >
          {text}
        </span>
      ) : null}
      {rightContent}
    </animated.button>
  );
};

export default Button;
