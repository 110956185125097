import firebase from 'firebase/compat/app';
import { useState } from 'react';

import { profileSelector, updateProfile } from '@hints/client';
import SMSIcon from '@hints/shared/icons/integrations/SMS.svg';

import { LoaderIcon } from '../../../components/LoaderIcon';
import { QrCode } from '../../../components/QrCode';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { isMobile } from '../../../utils';
import { generateShortCode } from '../../../utils/generateShortCode';
import { openPopup } from '../../../utils/popup';
import { QrCodeConnect } from '../../Connect/components/QrCodeConnect';
import { usePopupConnect } from '../hooks/usePopupConnect';
import { InputIntegrationMetadata, IntegrationHook } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const DEFAULT_SMS_NUMBER = '+18886163040';

const useSmsConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const smsNumber = DEFAULT_SMS_NUMBER;

  const dispatch = useAppDispatch();

  const generateSmsToken = async () => {
    const smsToken = generateShortCode();
    await dispatch(updateProfile({ smsToken }));
    return smsToken;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const initialize = async () => {
    setToken(await generateSmsToken());
  };

  const openSMS = () => {
    let url = `sms:${smsNumber}`;
    if (!user?.smsNumber) {
      url += `;?&body=${encodeURIComponent(`/start ${token}`)}`;
    }
    return openPopup(url, 'sms');
  };

  const getSmsQrCodeString = () => {
    let url = `SMSTO:${smsNumber}`;
    if (!user?.smsNumber) {
      url += `:/start ${token} \nSend this token to start Hints bot. Standard sms fee applies.`;
    }
    return url;
  };

  const isConnected = !!user.smsNumber;
  const popupConnect = usePopupConnect(openSMS, isConnected);
  const connect = async () => {
    await popupConnect();
    setIsLoading(true);
  };

  const disconnect = () =>
    dispatch(
      updateProfile({
        smsNumber: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    initialize,
    isLoading,
    isConnected,
    connect: isMobile ? connect : () => {},
    disconnect,
    open: isMobile ? openSMS : undefined,
    customButton: isMobile ? undefined : (
      <div className="mt-2 flex flex-col space-y-4">
        <p className="text-gray-500">Open your camera and scan the QR code</p>
        <QrCode data={getSmsQrCodeString()} />
        <p className="flex pb-3 flex-row items-center justify-center space-x-2 text-sm text-gray-400 border-b border-b-neutral-200">
          <LoaderIcon />
          <span>Waiting for SMS</span>
        </p>
      </div>
    ),
    customButtonV2: isMobile
      ? undefined
      : ({ metadata, back }) => (
          <QrCodeConnect
            metadata={metadata}
            qrCodeString={getSmsQrCodeString()}
            back={back}
          >
            📲 To connect SMS, scan the QR code with your camera and send the
            authorization SMS
          </QrCodeConnect>
        ),
  };
};

export const sms: InputIntegrationMetadata = {
  title: 'SMS',
  logoUrl: SMSIcon,
  connectorHook: useSmsConnector,
};
