import { useState } from 'react';

import { ReactComponent as CheckIcon } from '@hints/shared/icons/check-sq-fr.2.svg';

import NotionDuplicateMobile from '../../../../assets/instructions/notion-template/notion-duplicate-mobile.png';
import NotionDuplicate from '../../../../assets/instructions/notion-template/notion-duplicate.png';
import { isMobile } from '../../../utils';
import { useNotionTemplate } from '../../QuickIntegration/hooks/useNotionTemplate';
import { MessageBubble } from '../components/MessageBubble';
import { NavigationButtons, NextButton } from '../components/NavigationButtons';
import { StepComponent, StepComponentProps } from './StepComponent';

export const NotionTemplateStep: StepComponent = ({
  navigation,
}: StepComponentProps) => {
  const template = useNotionTemplate();
  const [isNextButtonActive, setIsNextButtonActive] = useState(false);

  if (!template) {
    return null;
  }
  return (
    <div className="h-full flex flex-col justify-between space-y-4">
      <div className="h-full overflow-y-auto space-y-3">
        <MessageBubble>
          🙌 Hi, I'm Hints AI Assistant, I'll help you set up the automation of
          Notion Template
        </MessageBubble>
        <MessageBubble>
          <p className="mb-2 font-semibold font-sans">
            💾 Duplicate the template to your Notion workspace
          </p>{' '}
          <img
            src={isMobile ? NotionDuplicateMobile : NotionDuplicate}
            alt=""
            className="w-full h-auto rounded-lg mb-4"
          />
          <a
            href={template.url}
            target="_blank"
            rel="noreferrer"
            className="text-white flex justify-center items-center bg-gray-700 py-3 px-4 rounded-full mt-4 hover:bg-gray-900 transition-all duration-300 ease-in-out font-medium"
          >
            Copy "{template.name}"
          </a>
        </MessageBubble>
      </div>
      <div className="flex flex-col space-y-5">
        <button
          type="button"
          className="flex flex-row space-x-2"
          onClick={() => setIsNextButtonActive((prev) => !prev)}
        >
          <CheckIcon
            primary={isNextButtonActive ? '#ffffff' : '#a5c2c4'}
            secondary={isNextButtonActive ? '#4d9985' : '#a5c2c4'}
            className="w-6 h-6 shrink-0"
          />
          <div className="text-left">
            I’ve copied the template to my workspace
          </div>
        </button>
        <NavigationButtons>
          <NextButton
            onClick={() => navigation.next?.()}
            isHidden={!isNextButtonActive}
          />
        </NavigationButtons>
      </div>
    </div>
  );
};
