import { FC, ReactNode, useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { Button } from './Button';

interface ButtonProps {
  type?: string;
  title: string;
  onClick: () => void;
  isSecondary?: boolean;
}

interface ModalProps {
  isShown: boolean;
  titleIcon?: string;
  title?: string | undefined;
  children: ReactNode;
  buttons: ButtonProps[];
  hasCancel?: boolean;
  header?: ReactNode;
  onClose?: () => void;
}

export const ModalHeader: FC<{
  iconUrl: string;
  title: string;
  rightContent?: JSX.Element;
}> = ({ iconUrl, title, rightContent }) => (
  <div className="flex justify-center relative w-full">
    <div className="flex mb-4 text-sm align-middle items-center justify-center self-center">
      <img src={iconUrl} alt={title} className="w-4 h-4 mr-2" />
      {title}
    </div>
    {rightContent ? (
      <div className="absolute top-0 right-0 cursor-pointer">
        {rightContent}
      </div>
    ) : null}
  </div>
);

export const Modal: FC<ModalProps> = ({
  isShown = false,
  title,
  titleIcon,
  children,
  buttons,
  hasCancel,
  header,
  onClose,
}: ModalProps) => {
  const [isModalVisible, setModalVisible] = useState(isShown);

  useEffect(() => {
    setModalVisible(isShown);
  }, [isShown]);

  const closeModal = () => {
    setModalVisible(false);
    onClose?.();
  };

  const props = useSpring({
    to: { translateY: 0 },
    from: { translateY: 200 },
  });

  const styles = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  return (
    <>
      {isModalVisible && (
        <div className="fixed z-20 inset-0 w-full h-full max-h-full md:p-6 flex items-end md:items-center justify-center overflow-hidden pointer-events-none">
          <animated.div
            style={props}
            className="bg-white p-6 rounded-t-3xl md:rounded-2xl shadow-s flex flex-col items-center md:max-w-[360px] w-full pointer-events-auto"
          >
            {header}
            {title && (
              <div className="flex items-center space-x-2">
                <img src={titleIcon} alt={title} className="w-5 h-5" />
                <span className="text-xl font-semibold text-neutral-800 font-sans text-center">
                  {title}
                </span>
              </div>
            )}
            <div className="w-full text-center text-base text-neutral-800 font-sans inline">
              {children}
            </div>
            {buttons.map((button) => (
              <Button
                key={button.title}
                type={button.type || 'primary'}
                onClick={button.onClick}
                text={button.title}
                isFull
              />
            ))}
            {hasCancel && (
              <div className="border-t md:border-0 p-3 md:p-0 mt-3 md:mt-1 w-full border-neutral-300 flex justify-center align-middle">
                <Button
                  text="Close"
                  type="inline"
                  onClick={() => closeModal()}
                  isFull
                />
              </div>
            )}
          </animated.div>
        </div>
      )}
      {isModalVisible && (
        <animated.div
          style={styles}
          onClick={() => closeModal()}
          className="fixed z-10 top-0 left-0 w-screen h-screen bg-black bg-opacity-40"
        />
      )}
    </>
  );
};

export default Modal;
