import { Timestamp } from './Timestamp';

export const SUBSCRIPTION_PLANS = ['productivity', 'crm', 'playbook'] as const;
export type SubscriptionPlan = (typeof SUBSCRIPTION_PLANS)[number];

export type Subscription = {
  userId: string;
  plan?: SubscriptionPlan;
  createdAt: Timestamp;
  status: 'active' | 'inactive' | 'incomplete';
  store?: 'app_store' | 'play_store' | 'stripe';

  testMode: boolean;

  startedAt?: Timestamp;
  canceledAt?: Timestamp;
  cancelsAt?: Timestamp;

  trial: boolean;
  trialEndsAt?: Timestamp;
};
export type SubscriptionWithId = Subscription & { id: string };

export function getSubscriptionPlan(
  subscription: Subscription | null,
): SubscriptionPlan | null {
  if (subscription?.status !== 'active') {
    return null;
  }
  return subscription.plan || 'productivity';
}

export default Subscription;
