import dedent from 'dedent-js';

import { getSupportLinks } from '../common/links';
import { OnboardingResponse } from '../types';

export default {
  getBotOnboardingText: (redirectUrl: string) => dedent`
    Hi, I am your personal Hints bot for SMS. All messages with the   #hashtag will be captured.
    ${redirectUrl ? `Finish your flow setup here ${redirectUrl}` : ''}
    ${getSupportLinks('intercom')}
  `,
} as OnboardingResponse;
