import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useState } from 'react';

const Alert = ({ text }: { text: string }) => {
  const [isHidden, setIsHidden] = useState(false);
  if (isHidden) {
    return null;
  }
  return (
    <div className="bg-yellow-50 px-6 py-4 w-full rounded-md">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-xs font-medium text-yellow-800">{text}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              onClick={() => setIsHidden(true)}
              className="inline-flex rounded-md bg-yellow-50 p-1.5 text-yellow-500 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
            >
              <span className="sr-only">Dismiss</span>
              <XMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
