import { createAsyncThunk } from '@reduxjs/toolkit';

import { HistoryLog, HistoryLogWithId } from '@hints/types';

import { uidSelector } from '../selectors';
import { RootState, ThunkExtra } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const createHistoryLog = createAsyncThunk<
  HistoryLogWithId | undefined,
  HistoryLog,
  {
    extra: ThunkExtra;
    state: RootState;
    rejectValue: undefined;
  }
>(`historyLog/create`, async (data, { getState, extra: { getFirestore } }) => {
  const state = getState();
  const uid = uidSelector(state);
  const firestore = getFirestore();

  if (!uid) {
    return undefined;
  }

  try {
    const ref = await firestore.collection('historyLog').add(data);

    const snapshot = await ref.get();
    if (snapshot.exists) {
      return { ...snapshot.data(), id: snapshot.id } as HistoryLogWithId;
    }
    return undefined;
  } catch (err) {
    console.error(err);
    return undefined;
  }
});

export const updateHistoryLog = createAsyncThunk<
  HistoryLogWithId | undefined,
  Partial<HistoryLogWithId>,
  {
    extra: ThunkExtra;
    state: RootState;
    rejectValue: undefined;
  }
>(`historyLog/update`, async (data, { getState, extra: { getFirestore } }) => {
  const state = getState();
  const uid = uidSelector(state);
  const firestore = getFirestore();

  if (!uid) {
    return undefined;
  }

  try {
    const ref = await firestore.doc(`historyLog/${data.id}`);

    // replace all undefined with firestore.FieldValue.delete()
    const preparedData = Object.fromEntries(
      Object.entries(data).map(([k, v]) =>
        v === undefined ? [k, firestore.FieldValue.delete()] : [k, v]
      )
    );

    await ref.update(preparedData);

    const snapshot = await ref.get();
    if (snapshot.exists) {
      return { ...snapshot.data(), id: snapshot.id } as HistoryLogWithId;
    }
    return undefined;
  } catch (err) {
    console.error(err);
    return undefined;
  }
});
