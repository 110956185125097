import {
  NotionTemplateData,
  NotionTemplateName,
  notionTemplates,
} from '@hints/constants';

import { useQueryParam } from '../../../hooks/useQueryParam';

export const useNotionTemplate = (): NotionTemplateData | null => {
  const templateId = (useQueryParam('template') ?? '') as NotionTemplateName;
  return notionTemplates[templateId] ?? null;
};
