import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';

import { profileSelector, updateProfile } from '@hints/client';

import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { getUrlWithQueryParams } from '../../../utils/getUrlWithQueryParams';
import { openPopup } from '../../../utils/popup';
import { IntegrationConnectionError } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const throwExpression = (errorMessage: string): never => {
  throw new Error(errorMessage);
};

const redirectUri = 'https://i.hints.so/integrations/complete-auth';

export function useOAuth2AuthorizationCodeGrantPopup({
  authUrl,
  onComplete,
}: {
  authUrl?: string;
  onComplete?: (
    code: string,
    redirectUri: string,
  ) => Promise<IntegrationConnectionError | null | undefined | void>;
}): [
  (customAuthUrl?: string) => void,
  boolean,
  IntegrationConnectionError | null,
] {
  const dispatch = useAppDispatch();
  const user = useAppSelector(profileSelector);
  const [token, setToken] = useState<string>('');
  const [getCustomToken] = useHttpsCallable('getCustomToken');

  const [externalPopup, setExternalPopup] = useState<Window | null>(null);
  const [isStarted, setIsStarted] = useState(false);

  const clearAuthorizationCode = () => {
    dispatch(
      updateProfile({
        currentOAuth2Code: FieldValue.delete() as unknown as undefined,
      }),
    );
  };

  const startAuth = (customAuthUrl?: string) => {
    setIsStarted(true);
    const url =
      customAuthUrl ??
      authUrl ??
      throwExpression(
        'You must provide authUrl to use "useOAuth2AuthorizationCodeGrantPopup" hook',
      );

    const authUrlWithRedirectUri = getUrlWithQueryParams(
      'https://i.hints.so/redirect',
      {
        url,
        redirect_uri: redirectUri,
        jwtToken: token,
      },
    );

    clearAuthorizationCode();
    const popup = openPopup(authUrlWithRedirectUri, 'oauth2popup');
    setExternalPopup(popup);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<IntegrationConnectionError | null>(null);

  useEffect(() => {
    if (!isStarted) return;
    (async function handlePopup() {
      if (user.currentOAuth2Code) {
        externalPopup?.close();
        setExternalPopup(null);
        setIsLoading(true);
        try {
          if (onComplete) {
            const result = await onComplete(
              user.currentOAuth2Code,
              redirectUri,
            );
            if (result?.message) {
              setError(result);
            }
          }
        } catch (e) {
          console.error(e);
          setError({ message: 'Something went wrong' });
        } finally {
          setIsLoading(false);
          clearAuthorizationCode();
          setIsStarted(false);
        }
      } else {
        console.error('no code');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.currentOAuth2Code]);

  useEffect(() => {
    if (!token) {
      (async function getToken() {
        setIsLoading(true);
        const response = await getCustomToken();
        if (response?.data) {
          setToken(response?.data as string);
          setIsLoading(false);
        }
      })();
    }
  }, []);

  return [startAuth, isLoading, error];
}
