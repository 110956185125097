/* eslint-disable no-control-regex */
import {
  curry,
  drop,
  isEmpty,
  pipe,
  prepend,
  replace,
  take,
  toUpper,
} from 'ramda';

export const capitalize = pipe(
  replace(/^.|[-\s]./g, toUpper),
  replace(/-/g, ' '),
);

export function truncateText(str: string, n: number, moreText = '...'): string {
  return str.length > n ? str.substr(0, n - 1) + moreText : str;
}

export function escapeHtml(str: string): string {
  return str.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function getTitleFromText(text: string): string {
  const title = text
    .split(/(\.\s|\n)/)
    .filter((t) => t.trim().length > 0)
    .shift();
  return truncateText(title ?? text, 80);
}

export const HASHTAGS_REGEX =
  /(?:^|[^\S\r\n])+#(?<tag>(?:[^\x00-\x7F]|\w|#)+)/gm;
const ONLY_HASHTAGS_REGEX = /^\s*#+\s*$/;

export const MENTIONS_REGEX =
  /(?:^|[^\S\r\n])+@(?<mention>(?:[^\x00-\x7F]|\w|@)+)/gm;
const ONLY_MENTIONS_REGEX = /^\s*@+\s*$/;

export function parseHashTags(text?: string) {
  if (!text) {
    return [];
  }
  // eslint-disable-next-line
  const HASHTAGS_REGEX = /(?:^|[^\S\r\n])+#(?<tag>(?:[^\x00-\x7F]|\w|#)+)/gm;
  return Array.from(text.matchAll(HASHTAGS_REGEX))
    .flatMap((m: any) => m.groups.tag?.toLowerCase()?.split('#'))
    .filter((tag) => tag);
}

export function removeHashTags(text: string) {
  return text
    .replace(HASHTAGS_REGEX, (match) =>
      // do not remove strings like '####'
      ONLY_HASHTAGS_REGEX.test(match) ? match : '',
    )
    .trim();
}

export function parseMentions(text?: string) {
  if (!text) {
    return [];
  }
  // eslint-disable-next-line
  const MENTIONS_REGEX =
    /(?:^|[^\S\r\n])+@(?<mention>(?:[^\x00-\x7F]|\w|@)+)/gm;
  return Array.from(text.matchAll(MENTIONS_REGEX))
    .flatMap((m) => m.groups!.mention?.toLowerCase()?.split('@'))
    .filter((mention) => mention);
}

export function removeMentions(text: string) {
  return text
    .replace(MENTIONS_REGEX, (match) =>
      // do not remove strings like '@@@@'
      ONLY_MENTIONS_REGEX.test(match) ? match : '',
    )
    .trim();
}

export const validateEmail = (email: string): boolean =>
  /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const removeExtraSpaces = (text: string): string => {
  const textWithoutNewLines = text.replace(/\n/g, ' ');
  let newText = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < textWithoutNewLines.length; i++) {
    if (textWithoutNewLines[i] === ' ' && textWithoutNewLines[i - 1] === ' ') {
      continue;
    }
    newText += textWithoutNewLines[i];
  }
  return newText;
};

// https://github.com/ramda/ramda/issues/774#issuecomment-72928478
export const groupsOf = curry(function group(
  n: number,
  list: any[] | string,
): any[] {
  return isEmpty(list)
    ? []
    : prepend(take(n, list as any[]), group(n, drop(n, list as any[])));
});
