import { filter, is, map, pipe, when } from 'ramda';
import { isPlainObj } from 'ramda-adjunct';

import { AnyObject } from '@hints/types';

export function removeUndefinedDeep<T>(object: T): T {
  return pipe(
    filter((prop) => prop !== undefined),
    map(when(is(Object), removeUndefinedDeep))
  )(object) as unknown as T;
}

export const replaceUndefinedWithNull = (object: AnyObject) =>
  Object.entries(object).reduce((result: AnyObject, [key, value]) => {
    const newObject = result;
    if (isPlainObj(value)) {
      newObject[key] = replaceUndefinedWithNull(value);
    } else {
      newObject[key] = value === undefined ? null : value;
    }
    return newObject;
  }, {});
