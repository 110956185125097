import { FC, PropsWithChildren } from 'react';
import { animated, useSpring } from 'react-spring';

export const MessageBubble: FC<PropsWithChildren<{ error?: boolean }>> = ({
  children,
  error,
}) => {
  const props = useSpring({
    to: { scale: 1 },
    from: { scale: 0 },
    delay: 100,
  });

  return (
    <animated.div
      style={props}
      className={`${
        error
          ? 'bg-red-100 text-red-900 border-2 border-red-800'
          : 'bg-white text-gray-800'
      } py-3 px-4 rounded-r-3xl rounded-tl-2xl rounded-bl origin-bottom-left`}
    >
      {children}
    </animated.div>
  );
};
