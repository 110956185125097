/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/mouse-events-have-key-events */

/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { integrationsArraySelector } from '@hints/client';
import { ReactComponent as QuestionMark } from '@hints/shared/icons/question-mark-cr-fr.2.svg';

import {
  RemoteSelect,
  useHttpsCallableRemote,
  useRemote,
} from '../../../components/remote-select';
import { useAppSelector, useEffectExceptOnMount } from '../../../hooks';
import { FlowSection } from '../FlowSection';
import { SetupComponent } from '../types';

export const ClickupSetup: SetupComponent<'clickup'> = ({
  integration,
  setIntegrationData,
  setIsIntegrationCanBeSaved,
  publishButtonPressed,
  setupStyle,
}) => {
  const [teamId, setTeamId] = useState<string | undefined>(integration.teamId);
  const [spaceId, setSpaceId] = useState<string | undefined>(
    integration.spaceId,
  );
  const [folderId, setFolderId] = useState<string | undefined>(
    integration.folderId,
  );
  const [listId, setListId] = useState<string | undefined>(integration.listId);
  const [listName, setListName] = useState<string | undefined>(
    integration.destinationName,
  );

  const userIntegrations = useAppSelector(integrationsArraySelector);
  const firstIntegrationSetup = userIntegrations.length === 0;

  const [workspaceTooltipVisible, setWorkspaceTooltipVisible] =
    useState<boolean>(false);
  const [spaceTooltipVisible, setSpaceTooltipVisible] =
    useState<boolean>(false);
  const [listTooltipVisible, setListTooltipVisible] = useState<boolean>(false);

  const teamsRemote = useHttpsCallableRemote('getClickupTeams');
  const spacesRemote = useHttpsCallableRemote('getClickupSpaces', { teamId });
  const foldersRemote = useHttpsCallableRemote('getClickupFolders', {
    spaceId,
  });
  const unfolderedListsRemote = useHttpsCallableRemote(
    'getClickupUnfolderedLists',
    { spaceId },
  );
  const listsRemote = useHttpsCallableRemote('getClickupLists', { folderId });
  const allListsRemote = useRemote({
    async load() {
      const remote = folderId ? listsRemote : unfolderedListsRemote;
      return remote.source.load();
    },
  });

  useEffect(() => {
    setIntegrationData({
      teamId,
      spaceId,
      folderId,
      listId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      destinationName: listName,
    });
    setIsIntegrationCanBeSaved(!!teamId && !!spaceId && !!listId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, spaceId, folderId, listId, listName]);

  useEffectExceptOnMount(() => {
    setSpaceId(undefined);
    setFolderId(undefined);
    setListId(undefined);
    setListName(undefined);
    if (teamId) {
      spacesRemote.reload();
    }
  }, [teamId]);

  useEffectExceptOnMount(() => {
    setFolderId(undefined);
    setListId(undefined);
    setListName(undefined);
  }, [spaceId]);

  useEffect(() => {
    if (spaceId) {
      foldersRemote.reload();
      allListsRemote.reload();
    }
  }, [spaceId]);

  useEffectExceptOnMount(() => {
    setListId(undefined);
    setListName(undefined);
    allListsRemote.reload();
  }, [folderId]);

  return (
    <div className="relative">
      <FlowSection
        completed={!!listId}
        step={firstIntegrationSetup ? 3 : 4}
        error={!listId && publishButtonPressed}
        errorText="select a clickup entities to continue"
        variant={setupStyle}
      >
        <div className="w-full flex flex-row space-x-1">
          <span>Select </span>
          <div className="flex space-x-1 cursor-pointer">
            <span
              className="underline"
              onClick={() =>
                setWorkspaceTooltipVisible(!workspaceTooltipVisible)
              }
              onMouseOver={() => setWorkspaceTooltipVisible(true)}
              onMouseLeave={() => setWorkspaceTooltipVisible(false)}
            >
              workspace
            </span>
            ,
            <span
              className="underline"
              onClick={() => setSpaceTooltipVisible(!spaceTooltipVisible)}
              onMouseOver={() => setSpaceTooltipVisible(true)}
              onMouseLeave={() => setSpaceTooltipVisible(false)}
            >
              space
            </span>
            ,
            <span
              className="underline"
              onClick={() => setListTooltipVisible(!listTooltipVisible)}
              onMouseOver={() => setListTooltipVisible(true)}
              onMouseLeave={() => setListTooltipVisible(false)}
            >
              list
            </span>
            <QuestionMark height={18} width={18} />
            <div
              className={`absolute -top-20 -left-2 p-2 bg-neutral-200 text-sm rounded-md w-full max-w-[380px] flex justify-center align-middle ${
                workspaceTooltipVisible
                  ? 'opacity-100 visible'
                  : 'opacity-0 invisible'
              } transition duration-300 ease-in-out}`}
            >
              <span>
                Workspaces are the top level of ClickUp Hierarchy and represent
                everyone and everything within an organization.
              </span>
            </div>
            <div
              className={`absolute -top-20 -left-2 p-2 bg-neutral-200 text-sm rounded-md w-full max-w-[380px] flex justify-center align-middle ${
                spaceTooltipVisible
                  ? 'opacity-100 visible'
                  : 'opacity-0 invisible'
              } transition duration-300 ease-in-out}`}
            >
              <span>
                Spaces act as the departments within your organization. Spaces
                allow your team to separate work and meet the needs of various
                teams.
              </span>
            </div>
            <div
              className={`absolute -top-20 -left-2 p-2 bg-neutral-200 text-sm rounded-md w-full max-w-[380px] flex justify-center align-middle ${
                listTooltipVisible
                  ? 'opacity-100 visible'
                  : 'opacity-0 invisible'
              } transition duration-300 ease-in-out}`}
            >
              <span>
                Lists are containers for your tasks. Think of Lists as tabs
                within your team folder.
              </span>
            </div>
          </div>
        </div>
      </FlowSection>
      <div className="mt-4 flex flex-col space-y-3">
        <RemoteSelect
          remote={teamsRemote}
          value={teamId}
          onChange={setTeamId}
          placeholder="Select workspace"
          selectFirst
          variant={setupStyle}
        />
        <RemoteSelect
          remote={spacesRemote}
          value={spaceId}
          onChange={setSpaceId}
          placeholder="Select space"
          disabled={!teamId}
          selectFirst
          variant={setupStyle}
        />
        {foldersRemote.options?.length ? (
          <RemoteSelect
            remote={{
              ...foldersRemote,
              options: [
                { id: '', name: '-- no folder --' },
                ...foldersRemote.options,
              ],
            }}
            value={folderId}
            onChange={setFolderId}
            placeholder="Select folder"
            disabled={!teamId || !spaceId}
            selectFirst
            variant={setupStyle}
          />
        ) : null}
        <RemoteSelect
          remote={allListsRemote}
          value={listId}
          onChange={(i) => {
            setListId(i);
            const list = allListsRemote?.options?.find((l) => l.id === i);
            setListName(list?.name);
          }}
          placeholder="Select list"
          disabled={!teamId || !spaceId}
          selectFirst
          variant={setupStyle}
        />
      </div>
    </div>
  );
};

export default ClickupSetup;
