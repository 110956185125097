/* eslint-disable jsx-a11y/no-autofocus */
import firebase from 'firebase/compat/app';
import { getFunctions } from 'firebase/functions';
import { useState } from 'react';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { v4 as uuidv4 } from 'uuid';

import { authSelector, profileSelector, updateProfile } from '@hints/client';
import MailIcon from '@hints/shared/icons/integrations/Mail.svg';

import { Button } from '../../../components';
import { Modal, ModalHeader } from '../../../components/Modal';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { openPopup } from '../../../utils/popup';
import {
  BackButton,
  NavigationButtons,
} from '../../Connect/components/NavigationButtons';
import { usePopupConnect } from '../hooks/usePopupConnect';
import { InputIntegrationMetadata, IntegrationHook } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const AnotherEmail = () => {
  const auth = useAppSelector(authSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [sendConfirmationEmail, isLoading, error] = useHttpsCallable(
    getFunctions(undefined, 'europe-west3'),
    'sendConfirmationEmail',
  );

  return (
    <>
      <Button
        text={auth?.emailVerified ? 'Use another email' : 'Link email'}
        onClick={async () => {
          setIsModalOpen(true);
        }}
        isFull
      />
      <Modal
        isShown={isModalOpen}
        buttons={[
          {
            title: 'Send activation link',
            onClick: async () => {
              sendConfirmationEmail({
                email,
                redirectUrl: `${window.location.href}?input=email`,
              });
              setIsModalOpen(false);
              setIsSuccessModalOpen(true);
            },
            type: 'primary',
          },
        ]}
        hasCancel
        header={<ModalHeader iconUrl={MailIcon} title="Email" />}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="w-full text-center mb-3">
          <div className="text-left"> Your email</div>
          <input
            autoFocus
            placeholder="Your email"
            className="w-full border border-gray-300 rounded-lg px-4 py-2 mt-2"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>
      </Modal>
      <Modal
        isShown={isSuccessModalOpen}
        buttons={[]}
        hasCancel
        header={<ModalHeader iconUrl={MailIcon} title="Email" />}
        onClose={() => {
          setIsSuccessModalOpen(false);
        }}
      >
        <span className="w-full text-center">
          We've sent you a confirmation email. Please check your inbox.
        </span>
      </Modal>
    </>
  );
};

const useEmailConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);
  const auth = useAppSelector(authSelector);

  const dispatch = useAppDispatch();

  const generateEmailToken = async () => {
    const emailToken = uuidv4();
    await dispatch(updateProfile({ emailToken }));
    return emailToken;
  };

  const [token, setToken] = useState('');
  const initialize = async () => {
    setToken(await generateEmailToken());
  };

  const openEmail = () => {
    const text = `${token}\n\nPlease send this token from the email address you want to use as a source (usually it is the email you use most often).`;
    const url =
      `mailto:save@hints.so?body=${encodeURIComponent(text)}` +
      `&subject=${encodeURIComponent('Link my email to Hints')}`;
    return openPopup(url, 'email');
  };

  const isConnected = !!user.linkedEmails?.length;
  const openEmailConnect = usePopupConnect(openEmail, isConnected);

  const [isLoading, setIsLoading] = useState(false);

  const disconnect = () =>
    dispatch(
      updateProfile({
        linkedEmails: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    initialize,
    isConnected,
    connect: () => {},
    disconnect,
    open: async () => openPopup('mailto:save@hints.so', 'email'),
    isLoading,
    customButton: (
      <div className="w-full flex flex-col space-y-2">
        {auth?.emailVerified ? (
          <Button
            text={`Use ${auth?.email}`}
            onClick={async () => {
              const emails = user?.linkedEmails ?? [];
              if (auth?.email != null) {
                emails.push(auth?.email);
              }
              await dispatch(updateProfile({ linkedEmails: emails }));
            }}
            isFull
          />
        ) : null}
        <AnotherEmail />
      </div>
    ),
    customButtonV2: ({ back }) => (
      <div className="h-full flex flex-col justify-between space-y-4">
        <div className="space-y-4">
          {auth?.emailVerified ? (
            <Button
              text={`Use ${auth?.email}`}
              onClick={async () => {
                const emails = user?.linkedEmails ?? [];
                if (auth?.email != null) {
                  emails.push(auth?.email);
                }
                await dispatch(updateProfile({ linkedEmails: emails }));
              }}
              isFull
            />
          ) : null}
          <AnotherEmail />
        </div>
        <NavigationButtons>
          <BackButton onClick={back} />
        </NavigationButtons>
      </div>
    ),
  };
};

export const email: InputIntegrationMetadata = {
  title: 'Email',
  logoUrl: MailIcon,
  connectorHook: useEmailConnector,
  successModalExtraText: (
    <p>
      Forward anything to
      <strong> save@hints.so</strong>. Don't forget to add your custom #hashtag
      from the flow setup.
    </p>
  ),
};
