import { createAsyncThunk } from '@reduxjs/toolkit';

import { User } from '@hints/types';

import { RootState, ThunkExtra } from '../types';

export const updateProfile = createAsyncThunk<
  Promise<void>,
  Partial<User>,
  { extra: ThunkExtra; state: RootState; rejectValue: undefined }
>(
  'user/updateProfile',
  async (data, { extra: { getFirestore, getFirebase } }) => {
    const firestore = getFirestore();

    const uid = getFirebase().auth().currentUser?.uid;
    if (!uid) {
      console.error('Could not determine user uid');
      return;
    }

    try {
      await firestore.doc(`users/${uid}`).update(data);
    } catch (err) {
      console.error(err);
    }
  }
);
