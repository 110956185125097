import { useEffect } from 'react';
import { ReduxFirestoreQuerySetting, useFirestore } from 'react-redux-firebase';

export const useFirestoreConnect = (data: ReduxFirestoreQuerySetting[]) => {
  const firestore = useFirestore();
  const firestoreIsEnabled = !!firestore;

  useEffect(() => {
    if (firestoreIsEnabled) {
      firestore.setListeners(data);
      return () => {
        firestore.unsetListeners(data);
      };
    }
    return () => {};
  }, [data]);
};
