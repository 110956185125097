import { getFunctions } from 'firebase/functions';
import { useEffect, useState } from 'react';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import { useNavigate } from 'react-router-dom';

import { Button, LoaderIcon } from '../components';
import useQuery from '../hooks/useQuery';

const LoadingMessages = [
  'connecting...',
  "we're linking your email",
  "don't close the tab",
];

export const LinkEmail = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [isCompleted, setIsCompleted] = useState(false);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [confirmEmail, isLoading, error] = useHttpsCallable(
    getFunctions(undefined, 'europe-west3'),
    'linkEmail',
  );

  const getLoadingMessage = () => {
    setTimeout(() => {
      setLoadingMessageIndex(loadingMessageIndex + 1);
    }, 2000);
    return LoadingMessages[loadingMessageIndex % LoadingMessages.length];
  };

  useEffect(() => {
    const token = decodeURIComponent(query.get('e') ?? '');
    const redirectUrl = decodeURIComponent(query.get('redirect_uri') ?? '');
    if (!token) {
      navigate('/');
      return;
    }
    if (isCompleted) return;

    confirmEmail({ e: token })
      .then(() => {
        if (redirectUrl) {
          window.location.assign(redirectUrl);
        }
      })
      .catch(console.error)
      .finally(() => setIsCompleted(true));
  }, []);

  if (isLoading) {
    return (
      <div className="w-full h-full flex flex-col justify-center items-center space-y-3">
        <LoaderIcon />
        <p className="text-gray-700 text-sm">{getLoadingMessage()}</p>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen flex items-center justify-center lg:justify-between px-12 md:px-24 bg-cover bg-vibrant dark:bg-none dark:bg-neutral-900">
      <div className="w-full h-full items-center justify-center flex flex-col">
        <div className="w-full max-w-xs">
          <div className="space-y-6 items-center justify-center select-none	">
            <div>
              <h1 className="text-neutral-900 dark:text-neutral-100 font-bold text-3xl text-center mb-4 select-none	">
                {error
                  ? 'Something went wrong'
                  : 'Your email successfully added'}
              </h1>
              {!error ? (
                <p className="text-md text-center text-neutral-900 dark:text-neutral-300">
                  You will be redirected to a page where you can complete the
                  integration settings. If the redirect did not happen, use the
                  button below
                </p>
              ) : null}
            </div>
            <div className="items-center flex space-y-4 flex-col">
              <Button
                onClick={() => {
                  navigate('/');
                }}
                isFull
                text="Go back to app"
                type="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkEmail;
