import firebase from 'firebase/compat/app';

import { profileSelector, updateProfile } from '@hints/client';
import GoogleCalendarIcon from '@hints/shared/icons/integrations/GoogleCalendar.svg';

import CalendarTutorialGif from '../../../../assets/TG-Calendar.png';
import CalendarStep2 from '../../../../assets/instructions/calendar/step-2.png';
import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { getUrlWithQueryParams } from '../../../utils/getUrlWithQueryParams';
import { useOAuth2AuthorizationCodeGrantPopup } from '../hooks/useOAuth2AuthorizationCodeGrantPopup';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const AUTH_URL = getUrlWithQueryParams(
  'https://accounts.google.com/o/oauth2/v2/auth',
  {
    client_id:
      '643347675469-kum1pvd9qvdukqc1hmp6lhr0a5g8us1r.apps.googleusercontent.com',
    scope: [
      'https://www.googleapis.com/auth/calendar.events',
      'https://www.googleapis.com/auth/contacts.readonly',
      'https://www.googleapis.com/auth/calendar.settings.readonly',
      // 'https://www.googleapis.com/auth/directory.readonly',
    ].join(' '),
    access_type: 'offline',
    response_type: 'code',
    prompt: 'consent',
    state: 'state_parameter_passthrough_value',
    include_granted_scopes: 'true',
  },
);

const useGoogleCalendarConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const dispatch = useAppDispatch();

  const [authorizeGoogleCalendar] = useHttpsCallable('authorizeGoogleCalendar');
  const [startAuth, isLoading, error] = useOAuth2AuthorizationCodeGrantPopup({
    authUrl: AUTH_URL,
    onComplete: async (code, redirectUri) => {
      await authorizeGoogleCalendar({ code, redirectUri });
    },
  });

  const disconnect = () =>
    dispatch(
      updateProfile({
        googleAuthTokens: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    isConnected: !!user.googleAuthTokens?.accessToken,
    connect: startAuth,
    disconnect,
    isLoading,
    error,
  };
};

export const googleCalendar: OutputIntegrationMetadata<'google-calendar'> = {
  title: 'Google Calendar',
  shortTitle: 'Calendar',
  logoUrl: GoogleCalendarIcon,
  connectorHook: useGoogleCalendarConnector,
  defaultTagName: 'calendar',
  firstStepName: 'Log in to Google Calendar',
  tutorial: {
    gif: (
      <img
        src={CalendarTutorialGif}
        alt=""
        className="w-full h-[160px] rounded-lg"
      />
    ),
    examples: [
      '✅ Now you can send invites and block time on your calendar. See examples below:',
      '🤖 alex.smith@hints.so Marketing sync on Friday at 11 am for 45 min',
      '🤖 Work on the investor deck tomorrow at 5 pm for 75 min',
      '🤖 peter@hints.so Quick catch up on Feb 1st at 2 pm SF time for 10 min',
    ],
  },
  instructions: (
    <div className="space-y-8">
      <div>
        <b>Step 1.</b> Click the “<b>Login to Google Calendar</b>” button below
      </div>
      <div>
        <span>
          <b>Step 2.</b> Choose your account in Google Calendar popup window
        </span>
        <img
          src={CalendarStep2}
          alt="step2"
          className="max-h-[300px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
    </div>
  ),
};
