import firebase from 'firebase/compat/app';
import { useState } from 'react';

import { profileSelector, updateProfile } from '@hints/client';
import ObsidianIcon from '@hints/shared/icons/integrations/Obsidian.svg';

import ObsidianTutorialGif from '../../../../assets/TG-Obsidian.png';
import ObsidianStep2 from '../../../../assets/instructions/obsidian/step-2.png';
import ObsidianStep3 from '../../../../assets/instructions/obsidian/step-3.png';
import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const useConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const [token, setToken] = useState<string | null>(null);
  const [getCustomToken] = useHttpsCallable('getCustomToken');

  const dispatch = useAppDispatch();

  const initialize = async () => {
    const response = await getCustomToken();
    if (response?.data) {
      setToken(response.data as string);
    }
  };

  const disconnect = () =>
    dispatch(
      updateProfile({
        obsidian: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    initialize,
    isConnected: !!user.obsidian?.connected,
    connect: () => {
      window.location.href = `obsidian://hints-auth?token=${token}`;
    },
    disconnect,
    isLoading: false,
    error: null,
  };
};

export const obsidian: OutputIntegrationMetadata<'obsidian'> = {
  title: 'Obsidian',
  logoUrl: ObsidianIcon,
  connectorHook: useConnector,
  defaultTagName: 'obsidian',
  firstStepName: 'Connect Obsidian',
  tutorial: {
    gif: (
      <img
        src={ObsidianTutorialGif}
        alt=""
        className="w-full h-[200px] rounded-lg"
      />
    ),
    examples: [
      '📝 To capture your notes, just send them into Assistant',
      '👥 To use this bot with your team or community, add it to your group and use #obsidian in your requests. Example: Read Action Inquiry by Bill Torbert #obsidian',
    ],
  },
  instructions: (
    <div className="space-y-8 mb-2">
      <div className="space-y-4">
        <div>
          <b>Step 1.</b> Install the “<b>Obsidian</b>” plugin from the official
          website
        </div>
        <a
          href="https://obsidian.md/plugins?id=hints-plugin"
          target="_blank noopener noreferrer"
          className="block px-2 py-2 rounded-lg bg-gray-50 bg-opacity-20 border-2 border-gray-400 hover:border-gray-800 transition duration-300 ease-in-out w-full overflow-hidden whitespace-nowrap text-ellipsis after:content-['↗'] text-center"
        >
          Install plugin{' '}
        </a>
      </div>
      <div>
        <span>
          <b>Step 2.</b> Enable installed plugin (enable button)
        </span>
        <img
          src={ObsidianStep2}
          alt="step2"
          className="max-h-[300px] middle:max-h-[150px] mt-2 rounded-lg"
        />
      </div>
      <div>
        <span>
          <b>Step 3.</b> Click on the “<b>Login to Obsidian</b> button below”
        </span>
      </div>
      <div>
        <span>
          <b>Step 4.</b>Done! You can use the integration. Perhaps you need to
          save to the daily note, in which case toggle this selector in the
          settings of the plugin
        </span>
        <img
          src={ObsidianStep3}
          alt="step3"
          className="max-h-[300px] middle:max-h-[150px] mt-2 rounded-lg"
        />
      </div>
    </div>
  ),
};
