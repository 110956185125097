import { useEffect } from 'react';

import { Select, SelectOption, SelectProps } from '../select/select';

export interface DataSource {
  load(): Promise<SelectOption[]>;
}

export interface Remote {
  source: DataSource;
  options: SelectOption[] | null;
  isLoading: boolean;
  isLoaded: boolean;
  load: () => Promise<void>;
  reset: () => void;
  reload: () => Promise<void>;
}

export interface RemoteSelectProps
  extends Omit<SelectProps, 'options' | 'isLoading'> {
  remote: Remote;
  canUpdate?: boolean;
}

export const RemoteSelect = ({
  remote,
  disabled,
  canUpdate = true,
  ...selectProps
}: RemoteSelectProps) => {
  const onLoad = () => {
    if (!disabled) {
      remote.load().catch();
    }
  };

  useEffect(() => {
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <Select
      {...selectProps}
      disabled={disabled}
      isLoading={remote.isLoading}
      isLoaded={remote.isLoaded}
      options={remote.options ?? []}
      onUpdate={canUpdate ? onLoad : undefined}
      noOptionsText={
        canUpdate
          ? "We couldn't find the destination. Try authorize again on step 3"
          : undefined
      }
    />
  );
};

export default RemoteSelect;
