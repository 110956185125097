import dedent from 'dedent-js';

import { getSupportLinks } from '../common/links';
import { OnboardingResponse } from '../types';

export default {
  getBotOnboardingText: (redirectUrl: string) => dedent`
    ${redirectUrl ? `\nFinish your flow setup here ${redirectUrl}\n` : ''}
    ${getSupportLinks('intercom')}
  `,
} as OnboardingResponse;
