import { assoc, filter, map, pipe, prop, reduce, toPairs } from 'ramda';

export interface NotionTemplateData {
  name: string;
  url: string;
  databaseName: string;
  hasCustomResponse?: boolean;
}

export type NotionTemplateName =
  | 'contentCreation'
  | 'personalCRM'
  | 'CRMByEaslo'
  | 'FundraisingCRM'
  | 'HabitTracker';

export const notionTemplates: Record<NotionTemplateName, NotionTemplateData> = {
  contentCreation: {
    name: 'Content Creation',
    url: 'https://bucoalejandro.notion.site/Content-Creation-b4f7d0d1e82148aeb06575e47ee1e680',
    databaseName: 'Content creation (Database)',
    hasCustomResponse: true,
  },
  personalCRM: {
    name: 'Simple Personal CRM',
    url: 'https://hintsapp.notion.site/Personal-CRM-03a14f0747ae4b4494b9eb09326ed1ba',
    databaseName: 'Contacts',
    hasCustomResponse: true,
  },
  CRMByEaslo: {
    name: 'CRM by Easlo',
    url: 'https://easlo.notion.site/CRM-1cfa55a6333a4588b72bc5061758702a',
    databaseName: 'Clients',
    hasCustomResponse: true,
  },
  FundraisingCRM: {
    name: 'FundraisingCRM by Hints',
    url: 'https://hintsapp.notion.site/Fundraising-CRM-325de3ba7d374a7b8d82c09fc8d1a914',
    databaseName: 'Investor Contacts',
    hasCustomResponse: true,
  },
  HabitTracker: {
    name: 'Notion Habit Tracker',
    url: 'https://www.notion.so/templates/notion-habit-tracker',
    databaseName: 'Habit tracker',
  },
};

export const databaseNameToNotionTemplateName: Record<
  string,
  NotionTemplateName
> = pipe(
  filter(prop<boolean>('hasCustomResponse')),
  toPairs<NotionTemplateData>,
  map(([templateName, { databaseName }]) => [databaseName, templateName]),
  reduce(
    (mapper, [databaseName, templateName]) =>
      assoc(databaseName, templateName, mapper),
    {},
  ),
)(notionTemplates);
