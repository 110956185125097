export const getUrlWithQueryParams = (
  url: string,
  queryParams: Record<string, string>,
) => {
  const queryParamsString = Object.keys(queryParams)
    .map(
      (i) => `${encodeURIComponent(i)}=${encodeURIComponent(queryParams[i])}`,
    )
    .join('&');
  return url + (url.includes('?') ? '&' : '?') + queryParamsString;
};
