import firebase from 'firebase/compat/app';

import { profileSelector, updateProfile } from '@hints/client';
import JiraIcon from '@hints/shared/icons/integrations/Jira.svg';

import JiraTutorialGif from '../../../../assets/TG-Jira.png';
import JiraStep2 from '../../../../assets/instructions/jira/step-2.png';
import JiraStep3 from '../../../../assets/instructions/jira/step-3.png';
import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { useOAuth2AuthorizationCodeGrantPopup } from '../hooks/useOAuth2AuthorizationCodeGrantPopup';
import { JiraSetup } from '../setups/JiraSetup';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const redirectURL = `${window.location.origin}/integrations/complete-auth`;

const useJiraConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);
  const authUrl = `https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=MVmBkGVUkCyktafmyazLG6Qgscf5rVe1&scope=read%3Ajira-work%20write%3Ajira-work%20offline_access&state=${user.id}&response_type=code&prompt=consent`;
  const dispatch = useAppDispatch();

  const [authorizeJira] = useHttpsCallable('authorizeJira');
  const [startAuth, isLoading, error] = useOAuth2AuthorizationCodeGrantPopup({
    authUrl,
    onComplete: async (code) => {
      await authorizeJira({ code, redirectUri: redirectURL });
    },
  });

  const disconnect = () =>
    dispatch(
      updateProfile({
        jira: FieldValue.delete() as unknown as undefined,
      }),
    );
  return {
    isConnected: !!user.jira?.accessToken,
    connect: startAuth,
    disconnect,
    isLoading,
    error,
  };
};

export const jira: OutputIntegrationMetadata<'jira'> = {
  title: 'Jira',
  logoUrl: JiraIcon,
  connectorHook: useJiraConnector,
  defaultTagName: 'jira',
  setupComponent: JiraSetup,
  tutorial: {
    gif: (
      <img
        src={JiraTutorialGif}
        alt=""
        className="w-full h-[160px] rounded-lg"
      />
    ),
    examples: [
      '✅ To create a task, simply send the Assistant your text',
      '📝 To send your comment to the task, reply to the original message with the task',
      '👥 To use this bot with your team, add it to your group and use #jira in your requests. Example: Launch a new website #jira',
    ],
    // youtubeId: 'Q6MniHKJASQ',
    // videoStartAt: 27,
  },
  instructions: (
    <div className="space-y-8">
      <div>
        <b>Step 1.</b> Click the “<b>Login to Jira</b>” button below
      </div>
      <div>
        <span>
          <b>Step 2.</b> Choose your account in Jira popup window
        </span>
        <img
          src={JiraStep2}
          alt="step2"
          className="max-h-[300px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
      <div>
        <span>
          <b>Step 3.</b> Give access to your Jira space
        </span>
        <img
          src={JiraStep3}
          alt="step2"
          className="max-h-[300px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
    </div>
  ),
};
