import { forwardRef } from 'react';

export const YoutubeVideo = forwardRef<
  HTMLIFrameElement,
  { videoId: string; className?: string; videoStartAt?: number }
>(({ videoId, className = '', videoStartAt }, ref) => (
  <iframe
    ref={ref}
    className={`rounded-lg aspect-video ${className}`}
    width="100%"
    src={`https://www.youtube.com/embed/${videoId}?start=${videoStartAt}`}
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
));
