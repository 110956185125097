import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { startLoadingApp, stopLoadingApp } from '@hints/client';

import mailbox from '../../assets/mailbox.svg';
import { GoBackButton, ResponsiveScreen } from '../components';
import { useAppDispatch, useHttpsCallable } from '../hooks';
import { useQueryParam } from '../hooks/useQueryParam';

export const EmailAuth = () => {
  const [sendSignInLinkToEmail] = useHttpsCallable('sendSignInLinkToEmail');
  const dispatch = useAppDispatch();
  const emailFromQueryParam = useQueryParam('email');
  const [emailAddress, setEmail] = useState(
    emailFromQueryParam || localStorage?.getItem('emailForSignIn') || '',
  );
  const onEmailPress = async () => {
    dispatch(startLoadingApp());
    try {
      await sendSignInLinkToEmail({
        email: emailAddress,
        redirectUrl: `${window.location.origin}/finishSignUp${window.location.search}`,
      });
      toast.success('Email sent!');
    } catch (error: any) {
      console.error(error);
      toast.error(error?.message || 'Something went wrong');
    } finally {
      dispatch(stopLoadingApp());
    }
  };

  useEffect(() => {
    if (emailFromQueryParam) {
      localStorage?.setItem('emailForSignIn', emailFromQueryParam);
    }
  }, [emailFromQueryParam]);

  return (
    <ResponsiveScreen>
      <div className="w-full mt-8 flex flex-col items-center z-10">
        <img
          src={mailbox}
          alt="mailbox"
          className="mb-8 h-[240px] md:h-[200px] md:tall:h-[240px] z-10"
        />

        <p className="mt-3.5 text-gray-800 text-center font-semibold">
          We have sent you a confirmation email to{' '}
          <span className="text-purple-500">{emailAddress}</span>. Please check
          your email and follow the link
        </p>
        <p className="mt-3.5 text-gray-500 text-center text-sm">
          Didn't receive the email?{' '}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <span
            className="underline font-medium cursor-pointer z-10 hover:text-gray-900 transition duration-300 ease-in-out"
            onClick={onEmailPress}
          >
            Send it again
          </span>
        </p>
      </div>
      <div className="flex h-full items-end justify-center z-10">
        <GoBackButton fullWidth routeToNavigate="/auth" label="Back" />
      </div>
    </ResponsiveScreen>
  );
};

export default EmailAuth;
