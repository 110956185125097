import { useEffect } from 'react';
import ReactSelect from 'react-select';

import { ReactComponent as UpdateIcon } from '@hints/shared/icons/refresh.svg';

export interface SelectOption {
  id: string;
  name: string;
}

export interface SelectProps {
  value?: string;
  options: SelectOption[];
  onChange?: (id: string) => void;
  onUpdate?: () => void;
  placeholder?: string;

  disabled?: boolean;
  isLoading?: boolean;
  isLoaded?: boolean;
  selectFirst?: boolean;

  tooltipLabel?: string;
  tooltipLink?: string;
  noOptionsText?: string;

  className?: string;
  variant?: 'normal' | 'v2';
}

export const Select = ({
  value,
  options,
  onChange,
  onUpdate,
  placeholder,
  noOptionsText,
  disabled = false,
  isLoading = false,
  isLoaded = false,
  selectFirst = false,
  tooltipLabel,
  tooltipLink,
  className = '',
  variant,
}: SelectProps) => {
  useEffect(() => {
    if (selectFirst && !value && options[0]?.id) {
      onChange?.(options[0].id);
    }
  }, [selectFirst, options]);

  const showUpdateIcon = isLoaded && !options.length && !!onUpdate;

  const selectedOption = options.find((option) => option.id === value);

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center space-x-3">
        <div className="relative w-full inline-block">
          <ReactSelect
            isLoading={isLoading}
            value={
              selectedOption
                ? { value: selectedOption.id, label: selectedOption.name }
                : undefined
            }
            onChange={(newValue) =>
              newValue?.value && onChange?.(newValue.value)
            }
            options={options.map((option) => {
              let label = option.name.slice(0, 50);
              if (option.name.length > 50) {
                label += '...';
              }
              return {
                value: option.id,
                label,
              };
            })}
            placeholder={placeholder}
            isDisabled={disabled || isLoading || showUpdateIcon}
            isSearchable={false}
            classNames={
              variant === 'v2'
                ? {
                    control: (state) =>
                      `!bg-white !bg-opacity-40 !rounded-xl !cursor-pointer !py-1 !px-4 !shadow-none !border ${
                        state.isFocused
                          ? '!border-gray-600'
                          : '!border-gray-600'
                      }`,
                    menu: () =>
                      '!bg-white !rounded-xl !shadow-xl !border-2 !border-gray-600 !overflow-hidden',
                    option: (state) =>
                      `!text-gray-600 !py-2 !px-4 !cursor-pointer ${
                        // eslint-disable-next-line no-nested-ternary
                        state.isSelected
                          ? '!bg-[#CBE5DE]'
                          : state.isFocused
                          ? '!bg-gray-100'
                          : ''
                      }`,
                  }
                : {}
            }
          />
          {/* TODO: add external search */}
          {/* eslint-disable-next-line no-nested-ternary */}
          {tooltipLink ? (
            <a
              className="flex text-sm text-gray-400 items-center justify-center space-x-1 px-1 h-[16px] absolute right-[32px] top-[calc(50%-8px)]"
              href={tooltipLink}
              target="_blank"
              rel="noreferrer"
            >
              <span className="underline">{tooltipLabel}?</span>
            </a>
          ) : tooltipLabel ? (
            <span className="flex text-sm text-gray-400 items-center justify-center space-x-1 px-1 h-[16px] absolute right-[32px] top-[calc(50%-8px)]">
              <span className="underline">{tooltipLabel}?</span>
            </span>
          ) : null}
        </div>
        {showUpdateIcon ? (
          <button
            type="button"
            onClick={onUpdate}
            className={`p-2 flex items-center justify-center border hover:bg-gray-100 rounded-lg transition-colors border-gray-300 bg-white text-gray-500 cursor-pointer ${
              variant === 'v2' ? 'bg-opacity-40 border-transparent py-2.5' : ''
            }`}
          >
            <UpdateIcon />
          </button>
        ) : null}
      </div>
      {showUpdateIcon && noOptionsText ? (
        <span className="text-gray-400 text-xs leading-6 font-medium font-sans">
          {noOptionsText}
        </span>
      ) : null}
    </div>
  );
};

export default Select;
