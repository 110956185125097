import { FirebaseApp } from '@firebase/app';
import 'firebase/compat/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/remote-config';
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config';

export const firebaseConfig = {
  apiKey: 'AIzaSyCcp8pZoqG2Ihe4uyeEFKqPIkNtqf6iuaw',
  authDomain: 'slipbox-6f705.firebaseapp.com',
  databaseURL:
    'https://slipbox-6f705-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'slipbox-6f705',
  storageBucket: 'slipbox-6f705.appspot.com',
  messagingSenderId: '643347675469',
  appId: '1:643347675469:web:1ef2238172221380b35f04',
  measurementId: 'G-6FGRVTCHJS',
};

const initRemoteConfig = async (app: FirebaseApp) => {
  const remoteConfig = getRemoteConfig(app);
  remoteConfig.settings.minimumFetchIntervalMillis = 60 * 5 * 5100;
  await fetchAndActivate(remoteConfig);
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
initRemoteConfig(firebaseApp).then().catch(console.error);

firebase.firestore();
firebase.functions();
firebase.remoteConfig();
firebase.analytics();

export default firebaseApp;
