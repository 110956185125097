import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from 'firebase/functions';

import { SelectOption } from '../select/select';
import { DataSource } from './remote-select';

export class HttpsCallableDataSource implements DataSource {
  private readonly mapper: (data: unknown) => SelectOption[];

  constructor(
    private readonly callableName: string,
    private readonly data: unknown = {},
    mapper?: (data: unknown) => SelectOption[],
  ) {
    this.mapper =
      mapper ?? ((responseData: unknown) => responseData as SelectOption[]);
  }

  async load() {
    const callable = httpsCallable(
      getFunctions(getApp(), 'europe-west3'),
      this.callableName,
    );
    const response = await callable(this.data);
    return this.mapper(response.data);
  }
}
