import dedent from 'dedent-js';

import { botLinks, getSupportLinks, supportLinks } from '../common/links';
import { OnboardingResponse } from '../types';

export default {
  getBotOnboardingText: (redirectUrl: string) => dedent`
    🟢 The Hints bot is connected.

    📝 To capture your data, just send them here

    😎 Don’t hesitate to get in touch with our support team ${
      supportLinks.whatsapp
    }
    ${redirectUrl ? `\nFinish your flow setup here ${redirectUrl}\n` : ''}
    By the way, we have WhatsApp bot for personal messages: ${botLinks.whatsapp}
    ${getSupportLinks('intercom')}
  `,
} as OnboardingResponse;
