import dedent from 'dedent-js';

import { BotCommand } from '@hints/types';

import { supportLinks } from '../common/links';
import { OnboardingResponse } from '../types';

export default {
  getBotOnboardingText: (redirectUrl?: string) => dedent`
    💡 Hints Assistant is here!
    ${redirectUrl ? `\nFinish your flow setup here ${redirectUrl}\n` : ''}
    ➕ To add a new connection to your Notion database — send me content for all relevant properties in a single request together with #notion. Example:

    Send to Notion: Marcus Brutus, type sports, Last Update - Chelsea gym, Last Contacted - today, Next step is to Decide on the class time #notion

    ✔️ To update existing connections — use natural language such as [change], [update] or [add to] in your request together with #notion. Example:

    Update Ophelia Winslet: last contacted today, last update: they're interested in hiring Alex, next step: share their contacts with each other #notion

    P.S. Don’t hesitate to get in touch with my support team ${
      supportLinks.intercom
    } or use /${BotCommand.help} for more commands
  `,
} as OnboardingResponse;
