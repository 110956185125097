import { GoogleAuthToken } from './Credentials';

export interface BaseIntegration {
  sources?: IntegrationSource[];
  destination: string;
  tagName: string;
  userId: string;
  destinationName?: string;
  from?: 'onboarding';
}

export interface NotionIntegration extends BaseIntegration {
  destination: 'notion';
  databaseId?: string;
  destinationId?: string;
  destinationType?: 'database' | 'page';
}

export interface CRMIntegration extends BaseIntegration {
  destination: 'hubspot' | 'pipedrive' | 'salesforce';
  pipelineId: string;
  stageId: string;
}

export interface GoogleCalendarIntegration extends BaseIntegration {
  destination: 'google-calendar';
  credentials: GoogleAuthToken;
}

export interface ClickupIntegration extends BaseIntegration {
  destination: 'clickup';
  listId?: string;
  folderId?: string;
  spaceId?: string;
  teamId?: string;
}

export interface CodaIntegration extends BaseIntegration {
  destination: 'coda';
  docId: string;
  tableId: string;
}

export interface TrelloIntegration extends BaseIntegration {
  destination: 'trello';
  boardId: string;
  listId: string;
}

export interface JiraIntegration extends BaseIntegration {
  destination: 'jira';
  resourceId: string;
  projectId: string;
  issueTypeId: string;
}

export interface ObsidianIntegration extends BaseIntegration {
  destination: 'obsidian';
}

export type Integration =
  | NotionIntegration
  | CRMIntegration
  | GoogleCalendarIntegration
  | ClickupIntegration
  | CodaIntegration
  | TrelloIntegration
  | JiraIntegration
  | ObsidianIntegration;
export type IntegrationWithId = Integration & { id: string };

export type IntegrationDestination = Integration['destination'];
export type IntegrationSourceToChooseByUser = 'telegram' | 'whatsapp' | 'sms';
export type IntegrationSource =
  | IntegrationSourceToChooseByUser
  | 'whatsapp-group'
  | 'email'
  | 'slack'
  | 'mobile'
  | 'crmchat';

export type DestinationToIntegrationMap = {
  [D in Integration['destination']]: IntegrationWithId extends infer U
    ? U extends { destination: string }
      ? D extends U['destination']
        ? { [K in keyof U]: U[K] }
        : never
      : never
    : never;
};

export const isCrmDestination = (d: IntegrationDestination | string) =>
  ['hubspot', 'salesforce', 'pipedrive'].includes(d);

export const isSalesDestination = (d: IntegrationDestination | string) =>
  isCrmDestination(d);

export default Integration;
