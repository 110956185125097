import { User } from '@hints/types';

import { ampli } from '../ampli';
import { getUser } from './user';

let currentUser: User | null = null;

const pushToGtmDataLayer = (data: Record<string, any>) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (dataLayer) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataLayer.push(data);
  }
};

export const gaTrack = (event: string, options: any = {}) => {
  pushToGtmDataLayer({ event, userId: currentUser?.id, ...options });
};

export const identify = async (userId: string) => {
  try {
    ampli.client.setUserId(userId);
    gaTrack('Identify', { userId });

    const user = await getUser(userId);
    if (user) {
      currentUser = user;
      // todo: track only needed properties
      ampli.identify(userId, user as any);
    }
  } catch (err) {
    console.error('Could not identify user', err);
  }
};
