import { HttpsCallableResult } from '@firebase/functions';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { Stripe } from 'stripe';

import { subscriptionSelector } from '@hints/client';
import {
  SUBSCRIPTION_PLANS,
  SubscriptionPlan,
  SubscriptionWithId,
  getSubscriptionPlan,
} from '@hints/types';
import { isProduction } from '@hints/utils';

import { ampli } from '../../ampli';
import { environment } from '../../environments/environment';
import { ButtonProps } from '../components';
import { LoaderIcon } from '../components/LoaderIcon';
import { useAppSelector, useEffectOnce, useHttpsCallable } from '../hooks';
import { useQueryParam } from '../hooks/useQueryParam';
import { getUrlWithQueryParams } from '../utils/getUrlWithQueryParams';

const getStripeData = (data: object) => ({
  ...data,
  ...(environment.stripeTestMode
    ? { testMode: environment.stripeTestMode }
    : {}),
});

type PricingTable = Array<
  Stripe.Product & {
    lookupKey: string | null;
    prices: {
      yearly: { id: string; unit_amount: number } | null;
      monthly: { id: string; unit_amount: number } | null;
    };
    features: { name: string }[];
  }
>;

const usePricingTable = (): PricingTable | null => {
  const [getPricingTable] = useHttpsCallable<PricingTable>('getPricingTable');
  const [pricingTable, setPricingTable] = useState<PricingTable | null>(null);
  useEffectOnce(() => {
    const loadTable = async () => {
      const res = await getPricingTable(getStripeData({}));
      if (res?.data) {
        setPricingTable(res.data);
      }
    };
    loadTable();
  });

  return pricingTable;
};

const SubscribeButton = ({ priceId }: { priceId: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const [createSession] = useHttpsCallable<{ id: string }>(
    'createStripeCheckoutSession',
  );
  const subscribe = async () => {
    setIsLoading(true);
    ampli.checkoutStarted({
      product: 'productivity',
    });
    const response = await createSession(
      getStripeData({
        priceId,
        successUrl: getUrlWithQueryParams(
          isProduction
            ? 'https://app.productivity-ai.net/payment-success'
            : 'http://localhost:1213/payment-success',
          {
            payment: 'success',
          },
        ),
        cancelUrl: window.location.href,
        referral: (window as any).tolt_referral ?? null,
      }),
    );
    if (response) {
      await stripe?.redirectToCheckout({
        sessionId: response.data.id,
      });
    }
  };
  return (
    <button
      type="button"
      onClick={subscribe}
      disabled={isLoading}
      className="w-full px-3 py-2 bg-indigo-100 rounded-md text-indigo-700"
    >
      {isLoading ? <LoaderIcon className="h-5 w-5" /> : null}
      Subscribe now
    </button>
  );
};

const ManageSubscriptionButton = ({
  store,
  type = 'default',
  text = 'Manage subscription',
}: {
  store: SubscriptionWithId['store'];
  type?: ButtonProps['type'];
  text?: string;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [createPortalUrl] = useHttpsCallable('createStripePortalUrl');
  const manageSubscription = async () => {
    switch (store) {
      case 'stripe':
        setIsLoading(true);
        // eslint-disable-next-line no-case-declarations
        const response = (await createPortalUrl(
          getStripeData({ returnUrl: window.location.href }),
        )) as HttpsCallableResult<{ url: string }>;
        window.location.href = response.data.url;
        break;
      case 'app_store':
        window.open('https://apps.apple.com/account/subscriptions', '_blank');
        break;
      case 'play_store':
        window.open(
          'https://play.google.com/store/account/subscriptions',
          '_blank',
        );
        break;
      default:
        console.error('Unknown store', store);
        // eslint-disable-next-line no-alert
        alert('Contact support to manage your subscription');
    }
  };
  return (
    <button
      type="button"
      onClick={manageSubscription}
      disabled={isLoading}
      className={`w-full px-3 py-2  rounded-md  ${
        type !== 'default'
          ? 'bg-indigo-100 text-indigo-700'
          : 'bg-gray-100 text-gray-700'
      }`}
    >
      {isLoading ? <LoaderIcon className="h-5 w-5" /> : null}
      {text}
    </button>
  );
};

const Subscribe = ({
  currentSubscription,
  minimumPlan,
}: {
  currentSubscription: SubscriptionWithId | null;
  minimumPlan: string | null;
}) => {
  const [period, setPeriod] = useState<'yearly' | 'monthly'>('monthly');

  const pricingTable = usePricingTable();
  const currentPlan = getSubscriptionPlan(currentSubscription);

  if (!pricingTable) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <LoaderIcon />
      </div>
    );
  }

  const getPlans = (): PricingTable => {
    let table = pricingTable;

    // hide lower tiers if minimumPlan is set
    if (minimumPlan) {
      const index = table.findIndex((plan) => plan.lookupKey === minimumPlan);
      table = table.slice(index);
    }

    // hide plans that doesn't have prices for the selected period
    table = table.filter(
      (plan) => !!plan.prices[period] && plan.lookupKey === 'productivity',
    );

    return table;
  };

  const getButtonProps = (
    plan: SubscriptionPlan,
  ): Pick<ButtonProps, 'type' | 'text'> => {
    if (currentPlan === plan) {
      return {
        type: 'default',
        text: 'Manage subscription',
      };
    }

    const plans = SUBSCRIPTION_PLANS;
    const isUpgrade =
      plans.indexOf(plan) >
      plans.indexOf((currentPlan ?? '') as SubscriptionPlan);

    if (isUpgrade) {
      return {
        type: 'primary',
        text: 'Upgrade to this plan',
      };
    }
    return {
      type: 'default',
      text: 'Downgrade to this plan',
    };
  };

  return (
    <section>
      {currentSubscription?.status !== 'active' ? (
        <div className="flex items-center justify-center mt-4 space-x-4">
          <button
            type="button"
            tabIndex={-1}
            className="text-base font-medium transition-colors hover:text-gray-600"
            onClick={() => setPeriod('monthly')}
          >
            Monthly
          </button>
          <button
            type="button"
            className="relative rounded-full focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={() =>
              setPeriod(period === 'monthly' ? 'yearly' : 'monthly')
            }
          >
            <div className="w-16 h-8 transition bg-indigo-500 rounded-full shadow-md outline-none" />
            <div
              className={`absolute inline-flex items-center justify-center w-6 h-6 transition-all duration-200 ease-in-out transform bg-white rounded-full shadow-sm top-1 left-1 ${
                period === 'monthly' ? 'translate-x-0' : 'translate-x-8'
              }`}
            />
          </button>
          <button
            type="button"
            tabIndex={-1}
            className="text-base font-medium transition-colors hover:text-gray-600"
            onClick={() => setPeriod('yearly')}
          >
            Annually, <span className="text-indigo-600">Save 30%</span>
          </button>
        </div>
      ) : null}
      <div className="py-4 px-4 mx-auto max-w-screen-xl lg:py-4 lg:px-4">
        <div className="space-y-8 flex flex-col lg:flex-row sm:gap-6 xl:gap-10 lg:space-y-0">
          {getPlans().map((product) => (
            <div
              key={product.id}
              className={`flex flex-col basis-0 flex-grow p-6 mx-auto max-w-md text-gray-900 bg-white rounded-2xl border xl:p-8 ${
                currentPlan === product.lookupKey
                  ? 'border-indigo-600'
                  : 'border-gray-200'
              }`}
            >
              <h3 className="mb-4 text-lg font-semibold">{product.name}</h3>
              <p className="text-sm text-gray-600">{product.description}</p>
              {currentSubscription?.status !== 'active' ? (
                <div className="flex justify-center items-baseline mt-8">
                  <span className="mr-2 text-5xl font-medium">
                    ${product.prices[period]!.unit_amount / 100}
                  </span>
                  <span className="text-gray-500">
                    /{period === 'monthly' ? 'month' : 'year'}
                  </span>
                </div>
              ) : null}
              <div className="mt-8">
                {currentSubscription?.status === 'active' ? (
                  <ManageSubscriptionButton
                    store={currentSubscription.store}
                    {...getButtonProps(product.lookupKey as SubscriptionPlan)}
                  />
                ) : (
                  <SubscribeButton priceId={product.prices[period]!.id} />
                )}
              </div>
              <ul className="mt-6 space-y-4 text-left">
                {product.features.map((feature) => (
                  <li key={feature.name} className="flex items-start space-x-3">
                    <CheckCircleIcon className="w-6 h-6 max-w-[16px] text-indigo-500" />
                    <span className="text-sm">{feature.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const Subscription = () => {
  const currentSubscription = useAppSelector(subscriptionSelector);
  const minimumPlan = useQueryParam('plan');

  return (
    <div className="w-full min-h-full flex flex-col items-center">
      <Subscribe
        currentSubscription={currentSubscription}
        minimumPlan={minimumPlan}
      />
    </div>
  );
};
