import dedent from 'dedent-js';

import { botLinks, getSupportLinks } from '../common/links';
import { OnboardingResponse } from '../types';

export default {
  getBotOnboardingText: (redirectUrl: string) => dedent`
    Hi, I am your personal Hints bot for WhatsApp. All messages with the #hashtag will be captured.
    ${redirectUrl ? `\nFinish your flow setup here ${redirectUrl}\n` : ''}
    By the way, we have WhatsApp bot for groups: ${botLinks.whatsappGroups}
    ${getSupportLinks('intercom')}
  `,
} as OnboardingResponse;
