import dedent from 'dedent-js';

import { guideLinks } from '../common/links';
import { OnboardingResponse } from '../types';

const response: OnboardingResponse = {
  getBotOnboardingText: (redirectUrl?: string) => dedent`
    Google Calendar is on ✔️
    ${
      redirectUrl
        ? `\n🔴 Finish your Calendar integration setup here ${redirectUrl}`
        : ''
    }
    Examples:

Create event: Work on the investor deck tomorrow at 5 pm for 75 min

Add multiple calendars: Team sync with Alex and John next friday 10:00AM #work

Get list of events: Send me my schedule for tomorrow

    More examples: ${guideLinks['google-calendar']}
    `,
};

export default response;
