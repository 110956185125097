import { FC } from 'react';

import { ReactComponent as CameraIcon } from '../../assets/camera-icon.svg';

export const QrCode: FC<{ data: string }> = ({ data }) => (
  <div className="relative flex justify-center">
    <img
      src={`https://qrcode.tec-it.com/API/QRCode?data=${encodeURIComponent(
        data,
      )}`}
      alt="Connection QR code"
    />
    <div className="absolute top-[50%] left-[50%] translate-x-[-40px] translate-y-[-40px]">
      <CameraIcon width={80} height={80} />
    </div>
  </div>
);
