// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const { NODE_ENV } = process.env;

export const isProduction = NODE_ENV === 'production';

export * from './aiPrompts';
export * from './notionTemplates';
export * from './onboardingResponses';
export * from './settings';
export * from './systemMessages';
