import dedent from 'dedent-js';

import { BotCommand } from '@hints/types';

import { supportLinks } from '../common/links';
import { OnboardingResponse } from '../types';

export default {
  getBotOnboardingText: (redirectUrl: string) => dedent`
    💡 Hints Assistant is here!
    ${redirectUrl ? `\nFinish your flow setup here ${redirectUrl}\n` : ''}
    ➕ To add a new investor to your Notion database — send me content for all relevant properties in a single request with #notion. Example:

    Send to Notion: New Fund, Intro by George Levin, Stage Idea, Portfolio Hints #notion

    ✔️ To update existing funds — use natural language such as [change], [update] or [add to] in your request with #notion. Example:

    Update New Fund, info – Great call, they asked to send them cohorts, new stage is Meeting done #notion

    P.S. Don’t hesitate to get in touch with my support team ${
      supportLinks.intercom
    } or use /${BotCommand.help} for more commands
  `,
} as OnboardingResponse;
