import format from 'date-fns/format';
import { getAuth, signOut } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { isEmpty } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';
import { actionTypes } from 'redux-firestore';

import {
  integrationsArraySelector,
  profileSelector,
  removeAllIntegrations,
  subscriptionSelector,
  updateProfile,
} from '@hints/client';
import { ReactComponent as ChevronRight } from '@hints/shared/icons/chevron-right-cr-fr.svg';
import { ReactComponent as Logout } from '@hints/shared/icons/logout.1.svg';
import { ReactComponent as ElectricalPlug } from '@hints/shared/icons/trash-can.2.svg';
import { IntegrationWithId, Timestamp } from '@hints/types';

import logo from '../../assets/logo-circle.svg';
import { useAppDispatch, useAppSelector } from '../../hooks';

import FieldValue = firebase.firestore.FieldValue;

const Integrations = [
  {
    name: 'Telegram',
    userFieldToCheck: 'telegramUserId',
  },
  {
    name: 'Whatsapp',
    userFieldToCheck: 'whatsAppNumber',
  },
  {
    name: 'SMS',
    userFieldToCheck: 'smsNumber',
  },
];

const SettingsGeneral = () => {
  const user = useAppSelector(profileSelector);
  const userIntegrations: IntegrationWithId[] = useAppSelector(
    integrationsArraySelector,
  );

  const isAdmin = user?.role === 'admin';
  const dispatch = useAppDispatch();

  const onLogoutPress = async () => {
    const auth = getAuth();
    dispatch({ type: actionTypes.CLEAR_DATA });
    await signOut(auth);
  };

  const resetOnboarding = () => {
    dispatch(
      updateProfile({
        showFinishedOnboarding: false,
      }),
    );
  };

  const resetUser = () => {
    dispatch(removeAllIntegrations({}));
    dispatch(
      updateProfile({
        botRedirectOutput: FieldValue.delete() as unknown as undefined,
        botRedirectUrl: FieldValue.delete() as unknown as undefined,
        clickup: FieldValue.delete() as unknown as undefined,
        hubspot: FieldValue.delete() as unknown as undefined,
        emailToken: FieldValue.delete() as unknown as undefined,
        googleAuthTokens: FieldValue.delete() as unknown as undefined,
        showFinishedOnboarding: FieldValue.delete() as unknown as undefined,
        integrationOnboardingStatus:
          FieldValue.delete() as unknown as undefined,
        isWhatsAppConnected: FieldValue.delete() as unknown as undefined,
        jira: FieldValue.delete() as unknown as undefined,
        linkedEmails: FieldValue.delete() as unknown as undefined,
        notion: FieldValue.delete() as unknown as undefined,
        obsidian: FieldValue.delete() as unknown as undefined,
        onboardingStatus: FieldValue.delete() as unknown as undefined,
        raycastToken: FieldValue.delete() as unknown as undefined,
        slackUserIds: FieldValue.delete() as unknown as undefined,
        smsNumber: FieldValue.delete() as unknown as undefined,
        smsToken: FieldValue.delete() as unknown as undefined,
        telegramChatId: FieldValue.delete() as unknown as undefined,
        telegramChatIds: FieldValue.delete() as unknown as undefined,
        telegramToken: FieldValue.delete() as unknown as undefined,
        telegramUserId: FieldValue.delete() as unknown as undefined,
        trello: FieldValue.delete() as unknown as undefined,
        whatsAppGroups: FieldValue.delete() as unknown as undefined,
        whatsAppGroupToken: FieldValue.delete() as unknown as undefined,
        whatsAppNumber: FieldValue.delete() as unknown as undefined,
        whatsAppToken: FieldValue.delete() as unknown as undefined,
        zapierToken: FieldValue.delete() as unknown as undefined,
      }),
    );
    toast.success('Está a fazer tabula rasa novamente', { duration: 2500 });
  };

  return (
    <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Profile
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Your Hints account information
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Full name
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">{user.name || 'Unknown'}</div>
              <button
                type="button"
                disabled
                className="font-semibold text-gray-200"
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Email address
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">{user.email || 'Unknown'}</div>
              <button
                type="button"
                disabled
                className="font-semibold text-gray-200"
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Id
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">{user.id || 'Unknown'}</div>
              <button
                type="button"
                disabled
                className="font-semibold text-gray-200"
              >
                Update
              </button>
            </dd>
          </div>
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              <button
                type="button"
                onClick={onLogoutPress}
                className="font-semibold text-red-600 hover:text-red-500"
              >
                Logout
              </button>
            </dt>
          </div>
          {isAdmin && (
            <div className="pt-6 sm:flex">
              <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                <button
                  type="button"
                  onClick={resetUser}
                  className="font-semibold text-red-600 hover:text-red-500"
                >
                  Reset User
                </button>
              </dt>
            </div>
          )}
        </dl>
      </div>
    </div>
  );
};

export default SettingsGeneral;
