/* eslint-disable react/prop-types */
import { ChangeEvent, FC, useState } from 'react';

interface Props {
  tagName: string;
  placeholder?: string;
  setTagName: (name: string) => void;
}

export const TagSetup: FC<Props> = ({ tagName, placeholder, setTagName }) => {
  const [showTagName, setShowTagName] = useState<boolean>(false);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = '';
    if (!e.target.value) {
      setShowTagName(false);
    } else if (e.target.value === '#') {
      setShowTagName(true);
    } else {
      setShowTagName(true);
      newValue = e.target.value.toLowerCase().replace(/[^_\p{L}\p{N}]+/gu, ''); // keep only letters, numbers and _)
    }
    setTagName(newValue);
  };

  return (
    <input
      spellCheck={false}
      className={`border border-gray-300 pl-4 pr-4 py-2 shadow-sm rounded-lg appearance-none hover:border-gray-400 disabled:bg-gray-100 w-full transition-colors ${
        tagName == null ? 'text-gray-400' : ''
      }`}
      type="text"
      value={showTagName || tagName ? `#${tagName}` : ''}
      onChange={onInputChange}
      maxLength={20}
      placeholder={placeholder ? `#${placeholder}` : ''}
    />
  );
};

export default TagSetup;
