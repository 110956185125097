import { useNavigate } from 'react-router-dom';
import { animated, useTrail } from 'react-spring';

import { isSalesDestination } from '@hints/types';

import { ampli } from '../../../ampli';
import { ResponsiveScreen } from '../../components';
import { useAllOutputs } from '../QuickIntegration/hooks/useOutputMetadata';
import { MessageBubble } from './components/MessageBubble';

export const ChooseDestination = () => {
  const navigate = useNavigate();
  const allOutputs = useAllOutputs();
  const destinations = allOutputs.filter(
    (dest) => !isSalesDestination(dest.key),
  );

  const destinationList = useTrail(destinations.length, {
    from: { translateY: 25, opacity: 0 },
    to: { translateY: 0, opacity: 1 },
    delay: 300,
    config: { tension: 280, friction: 20 },
  });

  return (
    <ResponsiveScreen
      classname="bg-[#CBE5DE] md:bg-[#CBE5DE]"
      noVerticalPadding
    >
      <div className="relative h-full w-full py-5">
        <div className="h-full flex flex-col space-y-4">
          <MessageBubble>
            🙌 Hi! I'm an AI assistant that can help manage your{' '}
            <strong className="font-semibold">🗂️ boards</strong>,{' '}
            <strong className="font-semibold">✅ tasks</strong>, and{' '}
            <strong className="font-semibold">📝 notes</strong> in different
            tools. Which one would you like to use?
          </MessageBubble>

          <animated.button
            style={destinationList[0]}
            key={destinations[0].key}
            type="button"
            className="flex justify-center items-center bg-white bg-opacity-40 hover:bg-opacity-80 transition-colors rounded-xl py-4"
            onClick={() => {
              ampli.destinationSelected({
                destination: destinations[0].key,
                product: 'productivity',
              });
              navigate(
                `/connect/any/${destinations[0].key}?destinationSelected=1`,
              );
            }}
          >
            <div className="h-[44px] bg-[#9AD2C3] rounded-full p-3">
              <img
                className="w-full h-full"
                src={destinations[0]?.logoUrl}
                alt={`${destinations[0]?.title} logo`}
              />
            </div>

            <div className="font-semibold font-sans text-gray-700 pl-2">
              {destinations[0]?.title}
            </div>
          </animated.button>

          <div className="grid grid-cols-2 gap-3">
            {destinationList.slice(1).map((props, i) => (
              <animated.button
                style={props}
                key={destinations[i + 1].key}
                type="button"
                className="flex flex-col items-center bg-white bg-opacity-40 hover:bg-opacity-80 transition-colors rounded-xl space-y-2 p-2.5"
                onClick={() => {
                  ampli.destinationSelected({
                    destination: destinations[i + 1].key,
                    product: 'productivity',
                  });
                  navigate(
                    `/connect/any/${
                      destinations[i + 1].key
                    }?destinationSelected=1`,
                  );
                }}
              >
                <div className="h-[44px] bg-[#9AD2C3] rounded-full p-3">
                  <img
                    className="w-full h-full"
                    src={destinations[i + 1]?.logoUrl}
                    alt={`${destinations[i + 1]?.title} logo`}
                  />
                </div>

                <span className="font-semibold font-sans text-gray-700">
                  {destinations[i + 1]?.title}
                </span>
              </animated.button>
            ))}
          </div>
        </div>
      </div>
    </ResponsiveScreen>
  );
};
