import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';
import { useNavigate, useSearchParams } from 'react-router-dom';

import gptSuccessImage from '../../../assets/gptSuccess.png';
import { Button } from '../../components';
import { ResponsiveScreen } from '../../components';
import { useInputMetadata } from '../QuickIntegration/hooks/useInputMetadata';

export const ConnectSuccess = () => {
  const [searchParams] = useSearchParams();
  const source = searchParams.get('source');
  const navigate = useNavigate();
  const [isConfettiRunning, setIsConfettiRunning] = useState(true);
  const sourceMetadata = useInputMetadata(source);
  const sourceConnector = sourceMetadata?.connectorHook();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsConfettiRunning(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <ResponsiveScreen
      classname="bg-[#D8CEFF] md:bg-[#D8CEFF]"
      noVerticalPadding
    >
      <div className="h-full flex flex-col justify-between pb-5">
        <div className="flex flex-col items-center h-full overflow-y-auto">
          <div className="mt-10 mb-5">
            <img
              src={gptSuccessImage}
              alt="Success"
              className="h-70 rounded-xl"
            />
          </div>
          <h3 className="mt-4 text-2xl font-bold text-center">
            AI Assistant successfully configured!
          </h3>
          <p className="mt-4 text-center text-opacity-40">
            Now you can go back to messaging app and say hi to the Assistant.
            You can ask any question you want in any language
          </p>
        </div>
        {sourceMetadata && sourceConnector && (
          <Button
            isFull
            size="big"
            type="primary"
            isRounded
            leftContent={
              <img
                src={sourceMetadata.logoUrl}
                className="w-6 h-6 mr-2"
                alt={`${sourceMetadata.title} logo`}
              />
            }
            text={`Let's Go`}
            onClick={() => {
              if (sourceConnector.open) {
                sourceConnector.open();
              } else {
                navigate('/');
              }
            }}
          />
        )}
        <Confetti
          numberOfPieces={isConfettiRunning ? 100 : 0}
          width={window.innerWidth > 370 ? 370 : window.innerWidth}
          height={window.innerHeight > 750 ? 750 : window.innerHeight - 30}
        />
      </div>
    </ResponsiveScreen>
  );
};
