import { useEffect, useState } from 'react';

import { IntegrationConnector } from '../types';

export const useConnectorInitializer = (connector?: IntegrationConnector) => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (connector) {
      if (connector.isConnected || connector.initialize === undefined) {
        setIsInitialized(true);
      } else if (!isInitialized) {
        connector.initialize().then(() => setIsInitialized(true));
      }
    }
  }, [connector?.isConnected]);

  return isInitialized;
};
