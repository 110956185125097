/* eslint-disable import/no-extraneous-dependencies, @typescript-eslint/ban-ts-comment */
import * as Sentry from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import firebase from 'firebase/compat/app';
import { useEffect, useMemo, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  ReactReduxFirebaseProvider,
  ReactReduxFirebaseProviderProps,
} from 'react-redux-firebase';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createFirestoreInstance } from 'redux-firestore';
import { PersistGate } from 'redux-persist/integration/react';

import {
  getIntegrationListeners,
  getSubscriptionListeners,
  loadingSelector,
  profileSelector,
  reduxFirebaseConfig,
  stopLoadingApp,
} from '@hints/client';

import { ampli } from '../ampli';
import { environment } from '../environments/environment';
import { persistor, store } from '../store';
import { Layout } from './Layout';
import { LoaderIcon } from './components';
import { AuthProvider, RequireAuth } from './contexts/AuthContext';
import { IntercomProvider } from './contexts/IntercomContext';
import { useFirestoreConnect } from './hooks';
import { Auth } from './pages/Auth';
import { ChooseDestination } from './pages/Connect/ChooseDestination';
import { Connect } from './pages/Connect/Connect';
import { ConnectSuccess } from './pages/Connect/ConnectSuccess';
import { EmailAuth } from './pages/EmailAuth';
import { FinishSignUp } from './pages/FinishSignUp';
import Home from './pages/Home';
import { IntercomPage } from './pages/Intercom';
import { LinkEmail } from './pages/LinkEmail';
import { Integrations } from './pages/ManageIntegrations/Integrations';
import CompleteAuth from './pages/QuickIntegration/CompleteAuth';
import { MenageDestination } from './pages/QuickIntegration/MenageDestination';
import Output from './pages/QuickIntegration/Output';
import { Redirect } from './pages/Redirect';
import Settings from './pages/Settings';
import SettingsGeneral from './pages/Settings/SettingsGeneral';
import SettingsSecurity from './pages/Settings/SettingsSecurity';
import { SlackAuth } from './pages/SlackAuth';
import { Subscription } from './pages/Subscription';
import { SuccessPage } from './pages/SuccessPage';
import { TutorialOutput } from './pages/Tutorials/TutorialOutput';

const rrfProps: ReactReduxFirebaseProviderProps = {
  firebase,
  config: reduxFirebaseConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
  initializeAuth: true,
};

ampli.load({
  environment: 'production',
  disabled: process.env.NODE_ENV !== 'production',
});

Sentry?.init({
  dsn: 'https://b3b438a37d3d4b4b9afc1d0653fb1022@o1040069.ingest.sentry.io/4503936504430592',
  integrations: [new Sentry.BrowserTracing()],
  enabled: process.env.NODE_ENV === 'production',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const RedirectComponent = ({ to }: { to: string }) => {
  const location = useLocation();
  return <Navigate to={`${to}${location.search}`} />;
};

export const Router = () => {
  const user = useSelector(profileSelector);
  const isAppLoading = useSelector(loadingSelector);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  // global firestore listeners
  const firestoreListeners = useMemo(
    () => [
      ...getSubscriptionListeners(user.id),
      ...getIntegrationListeners(user.id),
    ],
    [user.id],
  );
  useFirestoreConnect(firestoreListeners);

  useEffect(() => {
    if (
      // @ts-ignore
      window.Intercom &&
      user?.id &&
      window.location.pathname !== '/ai-chat'
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Intercom('boot', {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'si0ggno4',
        user_id: user?.id,
        email: user?.email,
        name: user?.name,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.Intercom('update', {
        hide_default_launcher: false,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    }
  }, [pathname, user]);

  useEffect(() => {
    dispatch(stopLoadingApp());
  }, []);

  return (
    <>
      <Routes>
        <Route path="auth" element={<Auth />} />
        <Route path="emailSent" element={<EmailAuth />} />
        <Route path="finishSignUp" element={<FinishSignUp />} />
        <Route path="support" element={<IntercomPage />} />
        <Route
          path="*"
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route path="" element={<Home />}>
            <Route index element={<Integrations />} />
            <Route
              path="integrations/complete-auth"
              element={<CompleteAuth />}
            />
            <Route path="integrations/output/:output" element={<Output />} />
            <Route
              path="integrations/output/:output/:id"
              element={<MenageDestination />}
            />
          </Route>
          <Route path="settings" element={<Settings />}>
            <Route index element={<SettingsGeneral />} />
            <Route path="security" element={<SettingsSecurity />} />
            <Route path="subscription" element={<Subscription />} />
          </Route>

          <Route path="connect/any/:destination" element={<Connect />} />
          <Route path="connect/:source/:destination" element={<Connect />} />
          <Route path="connect/success" element={<ConnectSuccess />} />
          <Route path="connect" element={<ChooseDestination />} />

          <Route
            path="subscription"
            element={<RedirectComponent to="/settings/subscription" />}
          />
          <Route path="payment-success" element={<SuccessPage />} />

          <Route path="tutorials/output/:output" element={<TutorialOutput />} />

          <Route path="setup-slack/*" element={<SlackAuth />} />
          <Route path="link-email/*" element={<LinkEmail />} />
          <Route path="redirect" element={<Redirect />} />
        </Route>
      </Routes>
      <Toaster />
      <ToastContainer />
      {isAppLoading && (
        <div className="fixed w-screen h-screen top-0 left-0 z-50 cursor-wait">
          <div className="fixed w-screen h-screen top-0 left-0 bg-black opacity-70" />
          <div className="w-screen h-screen flex justify-center items-center">
            <LoaderIcon />
          </div>
        </div>
      )}
    </>
  );
};

const stripePromise = loadStripe(environment.stripePublishableKey);

export const App = () => {
  const [isIntercomShow, setIsIntercomShow] = useState(false);
  return (
    <ReduxProvider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
          <IntercomProvider
            value={{ isShow: isIntercomShow, setIsShow: setIsIntercomShow }}
          >
            <AuthProvider>
              <Elements stripe={stripePromise}>
                <Router />
              </Elements>
            </AuthProvider>
          </IntercomProvider>
        </PersistGate>
      </ReactReduxFirebaseProvider>
    </ReduxProvider>
  );
};

export default App;
