import { FC, PropsWithChildren } from 'react';

import { ReactComponent as CheckIcon } from '@hints/shared/icons/check.svg';
import { ReactComponent as ErrorIcon } from '@hints/shared/icons/exclamation-mark.svg';

export const FlowSection: FC<
  PropsWithChildren<{
    completed: boolean;
    step: number;
    error: boolean;
    errorText: string;
    variant?: 'normal' | 'v2';
  }>
> = ({ completed, children, step, error, errorText, variant }) => (
  <>
    <div className="relative mt-3 mb-3 w-full flex flex-row space-x-1.5 items-center">
      {variant !== 'v2' ? (
        <div className="relative w-6 h-6 flex items-center justify-center">
          <div
            className={`w-4 h-4 rounded-full transition duration-300 ease-in-out ${
              // eslint-disable-next-line no-nested-ternary
              completed
                ? 'border-8 border-green-300'
                : error
                ? 'border-8 border-red-300'
                : 'border-8 border-gray-200'
            }`}
          />
          <div
            className={`absolute top-[9px] left-2 w-6 h-6 origin-top-left ${
              completed ? 'scale-100 opacity-100' : 'scale-50 opacity-0'
            } transition duration-300 ease-in-out`}
          >
            <CheckIcon fill="#059669" />
          </div>
          <div
            className={`absolute w-6 h-6 origin-top-left ${
              error && !completed
                ? 'scale-100 opacity-100'
                : 'scale-50 opacity-0'
            } transition duration-300 ease-in-out`}
          >
            <ErrorIcon primary="#EF4444" />
          </div>
          <div
            className={`absolute top-[8px] left-[7px] w-6 h-6 origin-top-left ${
              !completed && !error
                ? 'scale-100 opacity-100'
                : 'scale-50 opacity-0'
            } transition duration-300 ease-in-out`}
          >
            <span className="absolute -top-1 left-0.5 font-sans font-bold text-neutral-500 text-xs">
              {step}
            </span>
          </div>
        </div>
      ) : null}
      <span
        className={`${error ? 'text-red-500' : 'text-neutral-800'} ${
          variant === 'v2' ? '' : 'text-sm'
        } font-semibold flex`}
      >
        {children}
      </span>
    </div>
    {error && errorText ? (
      <div className="text-sm text-red-400 mb-3">{errorText}</div>
    ) : null}
  </>
);
