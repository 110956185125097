import firebase from 'firebase/compat/app';
import { useState } from 'react';

import { profileSelector, updateProfile } from '@hints/client';
import NotionIcon from '@hints/shared/icons/integrations/Notion.svg';

import NotionTutorialGif from '../../../../assets/TG-Notion.png';
import NotionStep2 from '../../../../assets/instructions/notion/step-2.png';
import NotionStep3 from '../../../../assets/instructions/notion/step-3.png';
import logo from '../../../../assets/logo-circle.svg';
import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { useOAuth2AuthorizationCodeGrantPopup } from '../hooks/useOAuth2AuthorizationCodeGrantPopup';
import { NotionSetup } from '../setups/NotionSetup';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const authUrl = `https://api.notion.com/v1/oauth/authorize?response_type=code&owner=user&client_id=b4e2c702-60da-477a-9bbf-e8f2843bd16b`;

const useNotionConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const dispatch = useAppDispatch();

  const [authorizeNotion] = useHttpsCallable('authorizeNotion');
  const [startAuth, isLoading, error] = useOAuth2AuthorizationCodeGrantPopup({
    authUrl,
    onComplete: async (code, redirectUri) => {
      await authorizeNotion({ code, redirectUri });
    },
  });
  const [isImageError, setIsImageError] = useState(false);

  const disconnect = () =>
    dispatch(
      updateProfile({
        notion: FieldValue.delete() as unknown as undefined,
      }),
    );

  const notionUser = user.notion?.user;

  return {
    isConnected: !!user.notion?.accessToken,
    connect: startAuth,
    disconnect,
    isLoading,
    error,
    customSuccessContent: notionUser ? (
      <div className="w-full flex justify-start items-start">
        {notionUser.avatar_url ? (
          <img
            className="w-6 h-6 rounded-full mr-3 bg-gray-100"
            src={isImageError ? logo : notionUser.avatar_url}
            alt={notionUser.name}
            onError={() => setIsImageError(true)}
          />
        ) : (
          <img
            src={NotionIcon}
            className="w-6 h-6 mr-3 bg-gray-100"
            alt="Notion logo"
          />
        )}
        <span className="text-gray-700">{notionUser.name}</span>
      </div>
    ) : undefined,
  };
};

export const notion: OutputIntegrationMetadata<'notion'> = {
  title: 'Notion',
  logoUrl: NotionIcon,
  connectorHook: useNotionConnector,
  defaultTagName: 'notion',
  setupComponent: NotionSetup,
  tutorial: {
    gif: (
      <img
        src={NotionTutorialGif}
        alt=""
        className="w-full h-[160px] rounded-lg"
      />
    ),
    examples: [
      '🗃 To update a database send to Assistant your text with all relevant parameters (such as assignee, due date, task type, etc.) in a single request. Example: Launch a new website, assign to Gosha, due January 27, marketing',
      '📝 For updating a simple page, just send to Assistant your text',
      '👥 To use this bot with your team, add it to your group and use #notion in your requests. Example: Cancel old subscriptions #notion',
    ],
  },
  instructions: (
    <div className="space-y-8">
      <div>
        <b>Step 1.</b> Click the “<b>Login to Notion</b>” button below
      </div>
      <div>
        <span>
          <b>Step 2.</b> Choose your account in Notion popup window
        </span>
        <img
          src={NotionStep2}
          alt="step2"
          className="max-h-[270px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
      <div>
        <span>
          <b>Step 3.</b> Give access to a page or a space
        </span>
        <img
          src={NotionStep3}
          alt="step3"
          className="max-h-[270px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
    </div>
  ),
};
