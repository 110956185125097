import { createSelector } from '@reduxjs/toolkit';
import { format, isToday } from 'date-fns';
import { reverse } from 'ramda';

import { ChatMessageWithId } from '@hints/types';

import { RootState } from '../types';

export const chatDataSelector = createSelector(
  (s: RootState) => s,
  (s: RootState) => s.firestore?.data?.chats || {},
);

export const chatArraySelector = createSelector(
  (s: RootState) => s,
  (s: RootState) => s.firestore?.ordered?.chats || [],
);

export const chatArraySelectorWithId = createSelector(
  (s: RootState) => s,
  (s: RootState) => {
    const chatData = chatDataSelector(s);
    return (
      (Object.keys(chatData).map((i) => ({
        id: i,
        ...chatData[i],
      })) as ChatMessageWithId[]) || []
    );
  },
);

type Section = {
  title: string;
  data: ChatMessageWithId[];
};

export const chatSectionsSelector = createSelector(
  (s: RootState) => s,
  (s: RootState) => {
    const sections: Section[] = [];
    const result: { [key: string]: ChatMessageWithId[] } = {};
    const arr = reverse(chatArraySelectorWithId(s));
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < arr.length; i++) {
      let date = new Date().toDateString();
      try {
        if (arr[i]?.createdAt.seconds) {
          date = new Date(
            arr[i]?.createdAt ? arr[i].createdAt.seconds * 1000 : '',
          ).toDateString();
        } else {
          date = new Date(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line no-underscore-dangle
            arr[i]?.createdAt ? arr[i].createdAt._seconds * 1000 : '',
          ).toDateString();
        }
      } catch (e) {
        console.error(e);
      }
      if (!result[date]) {
        result[date] = [];
      }
      result[date].push({ ...arr[i] });
    }
    return Object.keys(result).map((key) => ({
      title: isToday(new Date(key))
        ? 'Today'
        : format(new Date(key), 'MM/dd/yy'),
      data: result[key],
    }));
    return reverse(sections);
  },
);
