/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';

import { integrationsArraySelector, profileSelector } from '@hints/client';

import {
  RemoteSelect,
  useHttpsCallableRemote,
} from '../../../components/remote-select';
import { useAppSelector } from '../../../hooks';
import { FlowSection } from '../FlowSection';
import { SetupComponent } from '../types';

export const TrelloSetup: SetupComponent<'trello'> = ({
  integration,
  setIntegrationData,
  setIsIntegrationCanBeSaved,
  publishButtonPressed,
  setupStyle,
}) => {
  const user = useAppSelector(profileSelector);
  const [listId, setListId] = useState<string | undefined>(integration.listId);
  const [boardId, setBoardId] = useState<string | undefined>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    integration?.boardId || user?.trello.idBoards?.[0],
  );

  const userIntegrations = useAppSelector(integrationsArraySelector);
  const firstIntegrationSetup = userIntegrations.length === 0;

  const getTrelloBoards = useHttpsCallableRemote('getTrelloBoards');
  const getTrelloLists = useHttpsCallableRemote('getTrelloLists', boardId);

  useEffect(() => {
    setIntegrationData({
      listId,
      boardId,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      destinationName: `${
        getTrelloBoards.options?.find((i) => i.id === boardId)?.name
      } ${getTrelloLists.options?.find((i) => i.id === listId)?.name}`,
    });
    setIsIntegrationCanBeSaved(!!listId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId]);
  useEffect(() => {
    if (boardId) {
      getTrelloLists.reload();
    }
    if (boardId !== integration.boardId) {
      setListId(undefined);
    }
  }, [boardId]);

  return (
    <div className="relative">
      <FlowSection
        completed={!!listId}
        step={firstIntegrationSetup ? 3 : 4}
        error={!listId && publishButtonPressed}
        errorText="select a board to continue"
        variant={setupStyle}
      >
        <div className="w-full flex flex-row space-x-1">
          <span>Select </span>
          <span className="group flex space-x-1">
            <span>board and list</span>
          </span>
        </div>
      </FlowSection>
      <div className="flex flex-col space-y-4">
        <RemoteSelect
          remote={getTrelloBoards}
          value={boardId}
          onChange={setBoardId}
          placeholder="Select board"
          selectFirst
          variant={setupStyle}
        />
        <RemoteSelect
          remote={getTrelloLists}
          value={listId}
          onChange={setListId}
          placeholder="Select list"
          selectFirst
          canUpdate={false}
          disabled={!boardId}
          variant={setupStyle}
        />
      </div>
    </div>
  );
};

export default TrelloSetup;
