import { PropsWithChildren } from 'react';

import { QrCode } from '../../../components/QrCode';
import { IntegrationMetadata } from '../../QuickIntegration/types';
import { MessageBubble } from './MessageBubble';
import { BackButton, NavigationButtons, NextButton } from './NavigationButtons';

export const QrCodeConnect = ({
  children,
  metadata,
  qrCodeString,
  connect,
  back,
}: PropsWithChildren<{
  metadata: IntegrationMetadata;
  qrCodeString?: string;
  connect?: () => void;
  back: () => void;
}>) => (
  <div className="h-full flex flex-col justify-between space-y-4">
    <div className="space-y-4">
      <MessageBubble>{children}</MessageBubble>
      {qrCodeString ? (
        <>
          <div className="bg-white p-4 rounded-2xl">
            <QrCode data={qrCodeString} />
          </div>
          <div className="text-center text-gray-500 text-sm">
            Scan it with your Camera or QR Scanner
          </div>
        </>
      ) : null}
    </div>
    <NavigationButtons>
      <BackButton onClick={back} />
      {connect ? (
        <NextButton
          onClick={connect}
          leftContent={
            <img
              src={metadata.logoUrl}
              className="w-6 h-6 mr-3"
              alt={`${metadata.title} logo`}
            />
          }
          text={`Connect`}
        />
      ) : null}
    </NavigationButtons>
  </div>
);
