import { createContext, useContext } from 'react';

export const IntercomContext = createContext<{
  isShow: boolean;
  setIsShow: (show: boolean) => void;
}>({
  isShow: false,
  setIsShow: () => {},
});

export const useIntercom = () => {
  const { isShow, setIsShow } = useContext(IntercomContext);
  return [isShow, setIsShow];
};

export const IntercomProvider = IntercomContext.Provider;
