import toast from 'react-hot-toast';
import { Navigate, useParams } from 'react-router-dom';

import { integrationsArraySelector } from '@hints/client';
import { ReactComponent as CopyIcon } from '@hints/shared/icons/copy.svg';
import { IntegrationWithId } from '@hints/types';

import { Button, Header, Intercom, ResponsiveScreen } from '../../components';
import { YoutubeVideo } from '../../components/YoutubeVideo';
import { useAppSelector } from '../../hooks';
import {
  useAllInputs,
  useInputMetadata,
} from '../QuickIntegration/hooks/useInputMetadata';
import { useOutputMetadata } from '../QuickIntegration/hooks/useOutputMetadata';

export const TutorialOutput = () => {
  const { output } = useParams();
  const metadata = useOutputMetadata(output);

  const inputs = useAllInputs();

  const userIntegrations: IntegrationWithId[] = useAppSelector(
    integrationsArraySelector,
  );

  const currentIntegration = userIntegrations?.find(
    (integration) => integration.destination === output,
  );

  const connector = metadata?.connectorHook();

  const connectedInputs = inputs.filter(
    ({ connectorHook }) => connectorHook?.().isConnected,
  );

  const integrationSources = inputs.filter((i) =>
    currentIntegration?.sources?.includes(i.key),
  );

  const sources = integrationSources?.length
    ? integrationSources
    : connectedInputs;

  const firstInput = sources[0]?.key;

  const inputMetadata = useInputMetadata(firstInput);
  const inputConnector = inputMetadata?.connectorHook?.();

  const onTryItNowPress = () => {
    if (inputConnector?.open) {
      inputConnector?.open();
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText('save@hints.so');
    toast.success('Copied to clipboard');
  };

  if (!metadata || !connector || !metadata.tutorial) {
    return <Navigate to="/not-found" replace />;
  }

  return (
    <>
      <ResponsiveScreen>
        <Header title={`${metadata.title} examples`} routeToNavigate="/" />
        {/* {destination && tagName ? (
          <div className="flex items-center justify-between border border-gray-300 rounded-lg p-4 my-4">
            <Flow sources={sources} tagName={tagName} to={destination} />
          </div>
        ) : null} */}

        {/* <div className="text-base font-medium mb-2">
          {`✅ Your `}
          <span className="text-base font-semibold">{metadata.title}</span>
          {` integration is live`}
        </div> */}

        <div className="mt-2 flex flex-col space-y-6">
          {metadata.tutorial.gif ? metadata.tutorial.gif : null}
          {metadata.tutorial.youtubeId && !metadata.tutorial.gif ? (
            <YoutubeVideo
              videoId={metadata.tutorial.youtubeId}
              videoStartAt={metadata.tutorial.videoStartAt}
            />
          ) : null}

          {metadata.tutorial?.examples.map((example) => (
            <p key={example} className="text-gray-700 mb-2">
              {example}
            </p>
          ))}

          <Button
            onClick={onTryItNowPress}
            text={`Open ${
              firstInput.charAt(0).toUpperCase() + firstInput.slice(1)
            }`}
            isFull
          />
        </div>
      </ResponsiveScreen>
      <Intercom />
    </>
  );
};
