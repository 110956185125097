/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/20/solid';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  integrationsArraySelector,
  subscriptionSelector,
  uidSelector,
} from '@hints/client';
import { IntegrationWithId, getSubscriptionPlan } from '@hints/types';

import { ampli } from '../../../ampli';
import logo from '../../../assets/chrome.svg';
import Alert from '../../components/Alert';
import { useAppSelector } from '../../hooks';
import { classNames } from '../../utils/classNames';
import { useAllInputs } from '../QuickIntegration/hooks/useInputMetadata';
import { useAllOutputs } from '../QuickIntegration/hooks/useOutputMetadata';

const FlowLogo = ({ logoUrl, title }: { logoUrl: string; title: string }) => (
  <img
    width={18}
    height={18}
    src={logoUrl}
    alt={title}
    className="inline mr-2"
  />
);

export const Integrations = () => {
  const navigate = useNavigate();
  const inputs = useAllInputs();
  const outputs = useAllOutputs();
  const currentSubscription = useAppSelector(subscriptionSelector);
  const currentPlan = getSubscriptionPlan(currentSubscription) || '';

  const userId = useAppSelector(uidSelector);
  const userIntegrations: IntegrationWithId[] = useAppSelector(
    integrationsArraySelector,
  );

  useEffect(() => {
    ampli.identify(userId, {
      active_flows: userIntegrations.length,
      active_integrations: userIntegrations.map(
        ({ destination }) => destination,
      ),
    });
  }, []);

  return (
    <section className="w-full h-full p-6">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              {`Your active integrations`}{' '}
              <span
                className={`ml-1 ${
                  currentPlan === 'crm' &&
                  userIntegrations.length > 1 &&
                  'text-red-400'
                }`}
              >
                ({userIntegrations.length} / 1)
              </span>
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all tools connected to your account
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={() => navigate('/connect')}
              className="flex items-center space-x-1 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <PlusCircleIcon className="w-4 h-4" />
              <span className="whitespace-nowrap">Create Integration</span>
            </button>
          </div>
        </div>
        <div className="mt-6 flow-root">
          <Alert text="We do not recommend creating more than one integration to keep accuracy and speed on the high level" />
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8 mt-4">
            <div className="w-full overflow-x-auto py-2 align-middle">
              <table className="min-w-full border-separate border-spacing-0 bg-white rounded-md overflow-hidden border border-gray-200">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                    >
                      Source
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                    >
                      Hashtag
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    >
                      Destination
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userIntegrations.map(
                    ({ id, tagName, destination, sources }, index) => {
                      const from = inputs.filter((i) =>
                        sources?.includes(i.key),
                      );
                      const to = outputs.find((i) => i.key === destination);

                      return (
                        <tr
                          key={id}
                          onClick={() => {
                            const path = `/integrations/output/${destination}/${id}`;
                            if (
                              ['hubspot', 'pipedrive', 'salesforce'].includes(
                                destination,
                              )
                            ) {
                              window.open(`https://i.hints.so/${path}`);
                            } else {
                              navigate(path);
                            }
                          }}
                          className="bg-transparent hover:bg-gray-100 rounded-md cursor-pointer transition-all duration-300 ease-in-out"
                        >
                          <td
                            className={classNames(
                              index !== userIntegrations.length - 1
                                ? 'border-b border-gray-200'
                                : '',
                              'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-800 sm:pl-6 lg:pl-8 h-14 flex justify-start items-start',
                            )}
                          >
                            {sources?.length ? (
                              <div className="relative">
                                <FlowLogo
                                  logoUrl={from[0]?.logoUrl}
                                  title={from[0]?.title}
                                />
                                {sources[1] ? (
                                  <div className="absolute top-0 left-6">
                                    <FlowLogo
                                      logoUrl={from[1]?.logoUrl}
                                      title={from[1]?.title}
                                    />
                                  </div>
                                ) : null}
                                {sources?.length > 2 ? (
                                  <div className="absolute bottom-0 right-0">
                                    <div className="w-4 h-4 rounded-full bg-gray-100 flex items-center justify-center text-xs text-gray-600">
                                      +{sources && sources.length - 2}
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ) : (
                              <img
                                src={logo}
                                alt={destination}
                                className="w-5 h-5 mr-2"
                              />
                            )}
                            {from[0]?.title || 'Chrome extension'}
                          </td>
                          {tagName && currentPlan === 'productivity' && (
                            <td
                              className={classNames(
                                index !== userIntegrations.length - 1
                                  ? 'border-b border-gray-200'
                                  : '',
                                'whitespace-nowrap hidden px-3 py-4 text-sm text-gray-500 sm:table-cell',
                              )}
                            >
                              #{tagName}
                            </td>
                          )}
                          <td
                            className={classNames(
                              index !== userIntegrations.length - 1
                                ? 'border-b border-gray-200'
                                : '',
                              'whitespace-nowrap table-cell max-w-xs overflow-hidden px-3 py-4 text-sm text-gray-800',
                            )}
                          >
                            {to && (
                              <FlowLogo
                                logoUrl={to.logoUrl}
                                title={to?.title}
                              />
                            )}
                            {to?.title}
                          </td>
                          <td
                            className={classNames(
                              index !== userIntegrations.length - 1
                                ? 'border-b border-gray-200'
                                : '',
                              'relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-8 lg:pr-8',
                            )}
                          >
                            <PencilSquareIcon className="w-5 h-5 text-gray-400" />
                          </td>
                        </tr>
                      );
                    },
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Integrations;
