/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from 'react';

import { integrationsArraySelector } from '@hints/client';
import { NotionTemplateData } from '@hints/constants';
import { NotionIntegration } from '@hints/types';

import {
  RemoteSelect,
  useHttpsCallableRemote,
} from '../../../components/remote-select';
import {
  useAppSelector,
  useEffectOnce,
  useHttpsCallable,
} from '../../../hooks';
import { MessageBubble } from '../../Connect/components/MessageBubble';
import { FlowSection } from '../FlowSection';
import { useNotionTemplate } from '../hooks/useNotionTemplate';
import { SetupComponent } from '../types';

const useTemplateScreen = ({
  setIsNextButtonVisible,
  onSuccess,
}: {
  setIsNextButtonVisible?: (visible: boolean) => void;
  onSuccess: (id: string, template: NotionTemplateData) => void;
}) => {
  const template = useNotionTemplate();
  const [findNotionDatabase] = useHttpsCallable('findNotionDatabase');
  const tryCountRef = useRef(0);
  const [progress, setProgress] = useState(0);
  const [failed, setFailed] = useState(false);

  useEffectOnce(() => {
    if (!template) {
      return () => {};
    }
    setIsNextButtonVisible?.(false);

    const updateProgress = () => {
      tryCountRef.current += 1;
      setProgress(100 * (1 - Math.exp(-0.1 * tryCountRef.current)));
    };

    updateProgress();
    const intervalId = setInterval(async () => {
      updateProgress();
      const response = await findNotionDatabase({
        name: template.databaseName,
      });
      if (response?.data) {
        onSuccess(response.data as string, template);
        clearInterval(intervalId);
      }
      if (tryCountRef.current > 40) {
        clearInterval(intervalId);
        setFailed(true);
      }
    }, 3000);
    return () => {
      tryCountRef.current = 0;
      clearInterval(intervalId);
      setIsNextButtonVisible?.(true);
    };
  });

  if (!template) {
    return null;
  }

  if (failed) {
    return (
      <div className="w-full h-full flex flex-col space-y-4">
        <MessageBubble>
          🤔 I am sorry, I could not find your template. Please make sure you
          copied the template into your workspace.
        </MessageBubble>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col space-y-4">
      <MessageBubble>
        🔎 I am looking for your template and testing the integration. The
        process may take up to 2 minutes.
        <div className="mt-3 w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
          <div
            className="bg-indigo-500 h-1 rounded-full"
            style={{ width: `${progress}%`, transition: 'width 4s linear' }}
          />
        </div>
      </MessageBubble>
      <MessageBubble>
        📼 While you're waiting, I wanted to briefly explain how to use the
        integration in this video
        <div className="mt-3">
          <a
            href="https://youtu.be/9EtWZ3RCOv8"
            target="_blank"
            rel="noreferrer"
            className="flex justify-center items-center border-2 border-gray-300 bg-transparent py-2 px-4 rounded-full mt-4 hover:border-gray-500 hover:bg-gray-100 transition-all duration-300 ease-in-out font-semibold"
          >
            Watch Tutorial
          </a>
        </div>
      </MessageBubble>
      <MessageBubble>
        👉 If you have not copied the template into your workspace, please, go
        back and try again
      </MessageBubble>
    </div>
  );
};

export const NotionSetup: SetupComponent<'notion'> = ({
  integration,
  setIntegrationData,
  setIsIntegrationCanBeSaved,
  publishButtonPressed,
  setupStyle,
  setIsNextButtonVisible,
  navigation,
}) => {
  const [destinationData, setDestinationData] = useState({
    databaseId: undefined,
    destinationName: integration.destinationName,
    destinationId: integration.databaseId || integration.destinationId,
    destinationType: integration.databaseId
      ? 'database'
      : integration.destinationType,
  });

  const destinationsRemote = useHttpsCallableRemote('getNotionDestinations');
  const userIntegrations = useAppSelector(integrationsArraySelector);
  const firstIntegrationSetup = userIntegrations.length === 0;

  const onDestinationChange = (destinationId: string) => {
    const destination: any = destinationsRemote.options?.find(
      (d) => d.id === destinationId,
    );

    if (destination) {
      setDestinationData({
        destinationName: destination.name,
        databaseId: undefined,
        destinationId: destination.id,
        destinationType: destination.type,
      });
    }
  };

  useEffect(() => {
    setIntegrationData(destinationData);
    setIsIntegrationCanBeSaved(!!destinationData.destinationId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destinationData.destinationId, destinationData.destinationName]);

  const templateScreen = useTemplateScreen({
    setIsNextButtonVisible,
    onSuccess: (id, template) => {
      const newDestinationData = {
        destinationName: template.databaseName,
        databaseId: undefined,
        destinationId: id,
        destinationType: 'database' as NotionIntegration['destinationType'],
      };
      setDestinationData(newDestinationData);
      setIntegrationData(newDestinationData);
      navigation?.next?.();
    },
  });
  if (templateScreen) {
    return templateScreen;
  }

  return (
    <div className="relative">
      <FlowSection
        completed={!!destinationData.destinationId}
        step={firstIntegrationSetup ? 3 : 4}
        error={!destinationData.destinationId && publishButtonPressed}
        errorText="select a page to continue"
        variant={setupStyle}
      >
        <div className="w-full flex flex-row space-x-1">
          <span>Select a page</span>
        </div>
      </FlowSection>
      <RemoteSelect
        remote={destinationsRemote}
        value={destinationData.destinationId}
        onChange={onDestinationChange}
        placeholder="Select a page"
        selectFirst
        variant={setupStyle}
      />
    </div>
  );
};

export default NotionSetup;
