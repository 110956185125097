import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';

export function getSubscriptionListeners(
  uid: string | undefined
): ReduxFirestoreQuerySetting[] {
  if (!uid) {
    return [];
  }
  return [
    {
      collection: `subscriptions`,
      where: [`userId`, '==', uid],
      storeAs: 'userSubscriptions',
    },
  ];
}
