import { IntegrationSource } from '@hints/types';

import { SourceSelector } from '../components/SourceSelector';
import { StepComponent, StepComponentProps } from './StepComponent';

export const SourceStep: StepComponent = ({
  source,
  metadata,
  navigation,
  setIntegration,
}: StepComponentProps) => {
  const onSelect = (s: IntegrationSource | undefined) =>
    setIntegration((prev) => ({
      ...prev,
      sources: s ? [s] : [],
    }));

  return (
    <SourceSelector
      source={source}
      destination={metadata?.key}
      onSelect={onSelect}
      onConnect={() => navigation.next?.()}
      onBack={() => navigation.back?.()}
    />
  );
};
