import { ReduxFirestoreQuerySetting } from 'react-redux-firebase';

// eslint-disable-next-line import/prefer-default-export
export function getIntegrationListeners(
  uid: string | undefined
): ReduxFirestoreQuerySetting[] {
  if (!uid) {
    return [];
  }
  return [
    {
      collection: `integrations`,
      where: [`userId`, '==', uid],
      storeAs: 'userIntegrations',
    },
  ];
}
