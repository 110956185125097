import firebase from 'firebase/compat/app';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { profileSelector, updateProfile } from '@hints/client';
import TelegramIcon from '@hints/shared/icons/integrations/Telegram.svg';

import { Button, LoaderIcon } from '../../../components';
import { QrCode } from '../../../components/QrCode';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { isMobile } from '../../../utils';
import { openPopup } from '../../../utils/popup';
import { QrCodeConnect } from '../../Connect/components/QrCodeConnect';
import { usePopupConnect } from '../hooks/usePopupConnect';
import { InputIntegrationMetadata, IntegrationHook } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const useTelegramConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);
  const dispatch = useAppDispatch();
  const generateTelegramToken = async () => {
    const telegramToken = uuidv4();
    await dispatch(updateProfile({ telegramToken }));
    return telegramToken;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const initialize = async () => {
    setToken(await generateTelegramToken());
  };

  const getTelegramQrCodeString = () => {
    let url = `https://t.me/hints_app_bot?`;
    if (!user?.telegramChatId && !user.telegramChatIds?.find((_) => _)) {
      url += `start=${encodeURIComponent(token)}`;
    }
    return url;
  };

  const openTelegram = () => openPopup(getTelegramQrCodeString(), 'telegram');

  const isConnected =
    !!user.telegramChatId || !!user.telegramChatIds?.find((_) => _);
  const popupConnect = usePopupConnect(openTelegram, isConnected);
  const connect = async () => {
    await popupConnect();
    setIsLoading(true);
  };

  const disconnect = () =>
    dispatch(
      updateProfile({
        telegramChatId: FieldValue.delete() as unknown as undefined,
        telegramChatIds: FieldValue.delete() as unknown as undefined,
        telegramUserId: FieldValue.delete() as unknown as undefined,
      }),
    );
  return {
    initialize,
    isLoading,
    isConnected,
    connect: isMobile ? connect : () => {},
    disconnect,
    open: openTelegram,
    customButton: isMobile ? undefined : (
      <div className="mt-2 flex flex-col space-y-4">
        <Button
          text="Connect Telegram"
          leftContent={isLoading && <LoaderIcon />}
          disabled={isLoading}
          onClick={() => connect()}
        />
        <p className="text-gray-500">
          Or scan the QR code using the camera app on your phone. Don't use
          Telegram app to scan the QR code.
        </p>
        <QrCode data={getTelegramQrCodeString()} />
        <p className="flex pb-3 flex-row items-center justify-center space-x-2 text-sm text-gray-400 border-b border-b-neutral-200">
          <LoaderIcon />
          <span>Waiting for Telegram</span>
        </p>
      </div>
    ),
    customButtonV2: isMobile
      ? undefined
      : ({ metadata, back }) => (
          <QrCodeConnect
            metadata={metadata}
            qrCodeString={getTelegramQrCodeString()}
            connect={connect}
            back={back}
          >
            📲 To connect Telegram, click the “<b>Connect Telegram</b>” button
            below or scan the QR code with your camera to open the mobile
            version of Telegram app
          </QrCodeConnect>
        ),
  };
};

export const telegram: InputIntegrationMetadata = {
  title: 'Telegram',
  logoUrl: TelegramIcon,
  connectorHook: useTelegramConnector,
};
