import {
  IntegrationSource,
  IntegrationSourceToChooseByUser,
} from '@hints/types';

import { sms, telegram, whatsapp } from '../inputs';
import { InputIntegrationMetadata } from '../types';

export const INPUTS: {
  [key in IntegrationSourceToChooseByUser]: InputIntegrationMetadata;
} = {
  sms,
  whatsapp,
  telegram,
};

export function useInputMetadata(
  source: IntegrationSource | string | null | undefined,
): InputIntegrationMetadata | null {
  if (source && source in INPUTS) {
    return INPUTS[source as IntegrationSourceToChooseByUser];
  }
  return null;
}

export function useAllInputs(): (InputIntegrationMetadata & {
  key: IntegrationSourceToChooseByUser;
})[] {
  const keys = Object.keys(INPUTS) as IntegrationSourceToChooseByUser[];
  return keys.map((key) => ({
    key,
    ...INPUTS[key],
  }));
}
