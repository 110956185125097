import { createSelector } from '@reduxjs/toolkit';

import { DestinationToIntegrationMap, Integration } from '@hints/types';

import { RootState } from '../types';
import { uidSelector } from './user';

export const integrationsDataSelector = createSelector(
  (s: RootState) => s,
  (s: RootState) => s.firestore?.data?.userIntegrations || []
);

export const integrationsArraySelector = createSelector(
  (s: RootState) => s,
  (s: RootState) => s.firestore?.ordered?.userIntegrations || []
);

export const historyLogArraySelector = createSelector(
  (s: RootState) => s.firestore?.ordered?.userFailedHistoryLog ?? [],
  (items) => items
);

type IntegrationByDestinationSelectorType = <
  T extends Integration['destination']
>(
  state: RootState,
  destination: T
) => DestinationToIntegrationMap[T] | undefined;

export const integrationByDestinationSelector = createSelector(
  [
    integrationsArraySelector,
    uidSelector,
    (state, destination: Integration['destination']) => ({
      destination,
    }),
  ],
  (integrations, uid, { destination }) =>
    integrations?.find((i) => i.destination === destination && i.userId === uid)
) as IntegrationByDestinationSelectorType;
