import { ReactNode } from 'react';

import logo from '../../assets/logo-circle.svg';

export const ResponsiveScreen = ({
  noPadding,
  hasBackground,
  classname,
  children,
  small,
  noVerticalPadding,
}: {
  noPadding?: boolean;
  hasBackground?: boolean;
  classname?: string;
  children: ReactNode;
  small?: boolean;
  noVerticalPadding?: boolean;
}) => (
  <section className="relative w-full h-full flex justify-center items-center md:overflow-hidden tall:items-start md:tall:pt-[6vh] md:extratall:items-center md:extratall:pt-0">
    <div
      className={`border-white relative z-10 md:max-w-[370px] w-full h-full md:h-auto md:rounded-[32px] flex flex-col items-left justify-start md:mb-8 ${
        hasBackground ? 'bg-purple-200' : 'md:bg-white'
      } md:pb-6 md:pt-4 ${
        noPadding
          ? 'pt-4 px-0 md:px-0 md:pt-4 pb-0 md:pb-0'
          : 'md:px-7 p-6 md:p-4'
      } ${noVerticalPadding ? '!py-0' : 'py-6 md:p-4'} ${
        small ? '' : 'md:h-[90vh] md:tall:h-[85vh] overflow-visible'
      } md:overflow-auto md:shadow-[0_6px_24px_0px_rgb(0,0,0,0.05),0_0px_0px_1px_rgb(0,0,0,0.08)] md:max-h-[750px] ${classname}`}
    >
      {children}
    </div>
    <div className="fixed hidden md:flex top-2 w-full justify-between py-2 px-4 space-x-2">
      <div className="w-40 flex space-x-2 items-center">
        <img src={logo} className="w-7" />
        <span className="font-semibold text-gray-700">Hints</span>
      </div>
      <div className="w-40 flex justify-end">
        <a
          className="underline cursor-pointer text-neutral-400 hover:text-neutral-600 transition duration-300 ease-in-out"
          href="https://get.hints.so/iFAQ"
          target="_blank"
          rel="noreferrer"
        >
          F.A.Q.
        </a>
      </div>
    </div>
  </section>
);

export default ResponsiveScreen;
