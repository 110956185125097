import { IntegrationDestination, IntegrationSource } from './Integration';
import { Timestamp } from './Timestamp';

export enum HistoryLogStatus {
  Pending = 'pending',
  Sent = 'sent',
  Failed = 'failed',
}
export interface HistoryLog {
  integrationId: string;
  userId: string;
  createdAt: Timestamp;

  text: string;

  images?: string[];
  files?: string[];

  source: IntegrationSource;
  destination: IntegrationDestination;

  slackTeamId?: string;
  botMessageId?: string;
  botResponseMessageId?: string;
  botReplyToMessageId?: string;
  replyToMessageHistoryLogId?: string;
  botChatId?: string;
  /**
   * Bot identifier, for example, it can be Telegram bot ID, SMS or WhatsApp number.
   */
  botId?: string;
  integrationResult?: any;
  userName?: string;

  status: HistoryLogStatus;
  botResponseMessage?: string;

  // HistoryLogStatus.Sent
  sentAt?: Timestamp;

  // HistoryLogStatus.Failed
  failedAt?: Timestamp;
  errorMessage?: string;
  isErrorShown?: boolean;
}

export type HistoryLogWithId = HistoryLog & { id: string };

export type IntegrationResultType =
  | 'error'
  | 'create'
  | 'update'
  | 'comment'
  | 'retrieve'
  | 'remove';

export default HistoryLog;
