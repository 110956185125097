import axios from 'axios';
import firebase from 'firebase/compat/app';
import { useRef, useState } from 'react';

import { profileSelector, updateProfile } from '@hints/client';

import { LoaderIcon } from '../../../components/LoaderIcon';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { CodaSetup } from '../setups/CodaSetup';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const useConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const tokenInput = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();

  const saveToken = async () => {
    try {
      setIsLoading(true);
      const token = tokenInput.current?.value?.trim() ?? '';
      if (!token) {
        setError('API token is required');
        return;
      }
      await axios.get('https://coda.io/apis/v1/whoami', {
        headers: { Authorization: `Bearer ${token}` },
      });
      await dispatch(updateProfile({ coda: { token } }));
      setError('');
    } catch (err) {
      setError('Invalid token');
    } finally {
      setIsLoading(false);
    }
  };

  const disconnect = () =>
    dispatch(
      updateProfile({
        coda: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    isConnected: !!user.coda?.token,
    connect: () => {},
    disconnect,
    customButton: (
      <div className="flex flex-col space-y-4">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label className="flex flex-col">
          <div className="flex flex-row justify-between items-center">
            <span className="text-gray-600 font-medium">API token</span>
            <a
              href="https://coda.io/account#apiSettings"
              target="_blank"
              rel="noreferrer"
              className="text-sm text-blue-600 hover:text-blue-800 hover:underline"
            >
              Generate API token
            </a>
          </div>
          <input
            ref={tokenInput}
            type="text"
            className="px-2 py-2 w-full text-gray-600 border border-gray-300 disabled:bg-transparent rounded transition-colors"
          />
          {error ? (
            <div className="mt-1 text-red-700 text-sm">{error}</div>
          ) : null}
        </label>
        <button
          onClick={saveToken}
          type="button"
          className="px-4 py-2 w-full flex justify-center items-center text-gray-600 font-medium border border-gray-300 hover:bg-gray-100 disabled:bg-transparent rounded cursor-pointer transition-colors"
        >
          {isLoading && <LoaderIcon />}
          <span>Continue with Coda</span>
        </button>
      </div>
    ),
  };
};

export const coda: OutputIntegrationMetadata<'coda'> = {
  title: 'Coda',
  logoUrl:
    'https://upload.wikimedia.org/wikipedia/en/3/3f/Coda_%28document_editor%29_logo.png',
  connectorHook: useConnector,
  defaultTagName: 'coda',
  setupComponent: CodaSetup,
  instructions: <div></div>,
};
