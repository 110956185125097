import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { profileSelector, updateProfile } from '@hints/client';
import WhatsAppGroupIcon from '@hints/shared/icons/integrations/WhatsAppGroup.svg';

import { Button, LoaderIcon } from '../../../components';
import { QrCode } from '../../../components/QrCode';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { generateShortCode } from '../../../utils/generateShortCode';
import { openPopup } from '../../../utils/popup';
import { QrCodeConnect } from '../../Connect/components/QrCodeConnect';
import { usePopupConnect } from '../hooks/usePopupConnect';
import { InputIntegrationMetadata, IntegrationHook } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const DEFAULT_WHATSAPP_NUMBER = '+14067809666';

const useConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const [isLoading, setIsLoading] = useState(false);

  const whatsAppNumber = DEFAULT_WHATSAPP_NUMBER;

  const dispatch = useAppDispatch();
  const generateWhatsAppGroupToken = async () => {
    const whatsAppGroupToken = user.whatsAppNumber ? '' : generateShortCode();
    await dispatch(updateProfile({ whatsAppGroupToken }));
    return whatsAppGroupToken;
  };

  const [token, setToken] = useState('');
  const initialize = async () => {
    setToken(await generateWhatsAppGroupToken());
  };

  const getWhatsAppGroupQrCodeString = () => {
    const number = whatsAppNumber.startsWith('+')
      ? whatsAppNumber.slice(1)
      : whatsAppNumber;
    let url = `https://wa.me/${number}`;
    if (!user.whatsAppGroups?.length) {
      url += `?text=${encodeURIComponent(
        `/start ${token}${
          token ? ' \nSend this message to start Hints bot.' : ''
        }`,
      )}`;
    }
    return url;
  };

  const isConnected = !!user.whatsAppGroups?.length;

  useEffect(() => {
    if (isConnected) {
      setIsLoading(false);
    }
  }, [isConnected]);

  const openWhatsApp = () => {
    const url = getWhatsAppGroupQrCodeString();
    return openPopup(url, 'whatsapp', 800);
  };
  const popupConnect = usePopupConnect(openWhatsApp, isConnected);
  const connect = async () => {
    await popupConnect();
    setIsLoading(true);
  };

  const disconnect = () =>
    dispatch(
      updateProfile({
        whatsAppGroups: FieldValue.delete() as unknown as undefined,
        whatsAppNumber: user.isWhatsAppConnected
          ? user.whatsAppNumber
          : (FieldValue.delete() as unknown as undefined),
      }),
    );

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  return {
    initialize,
    isConnected,
    connect: () => {},
    open: openWhatsApp,
    disconnect,
    customButton: (
      <div className="mt-2 text-left">
        <ul className="space-y-4 text-gray-700">
          <Button
            isFull
            text="Connect WhatsApp Group"
            leftContent={isLoading && <LoaderIcon />}
            disabled={isLoading}
            onClick={() => connect()}
          />
          <li>
            <span className="text-gray-400 mr-1">1.</span>
            Or scan the QR code using the camera app on your phone. Don't use
            WhatsApp app to scan the QR code.
            <br />
            <QrCode data={getWhatsAppGroupQrCodeString()} />
          </li>
          <li>
            <span className="text-gray-400 mr-1">2.</span>
            Add Hints Group bot to your phone contacts
          </li>
          <li>
            <span className="text-gray-400 mr-1">3.</span>
            Now you can add Hints Group bot to any WhatsApp group
          </li>
        </ul>
        <p className="mt-6 flex flex-row items-center justify-center space-x-2 text-sm text-gray-400">
          <LoaderIcon />
          <span>Waiting for message</span>
        </p>
      </div>
    ),
    customButtonV2: ({ metadata, back }) => (
      <QrCodeConnect metadata={metadata} connect={connect} back={back}>
        <ul>
          <li>
            <span className="text-gray-400 mr-1">1.</span>
            Scan the QR code using the camera app on your phone. Don't use
            WhatsApp app to scan the QR code.
            <br />
            <QrCode data={getWhatsAppGroupQrCodeString()} />
          </li>
          <li>
            <span className="text-gray-400 mr-1">2.</span>
            Add Hints Group bot to your phone contacts
          </li>
          <li>
            <span className="text-gray-400 mr-1">3.</span>
            Now you can add Hints Group bot to any WhatsApp group
          </li>
        </ul>
      </QrCodeConnect>
    ),
  };
};

export const whatsappGroup: InputIntegrationMetadata = {
  title: 'WhatsApp group',
  logoUrl: WhatsAppGroupIcon,
  connectorHook: useConnector,
};
