import dedent from 'dedent-js';

import { OnboardingResponse } from '../../types';
import { getSupportLinks, guideLinks } from '../links';
import { OnboardingResponseParams } from '../types';

export default ({
  serviceName,
  serviceCode,
  hashtagName,
  managableEntity,
  example,
  mediaLink,
  supportLinkCode,
  hasMedia = true,
}: OnboardingResponseParams): OnboardingResponse => ({
  ...(hasMedia ? { mediaLink } : {}),

  getBotOnboardingText: (redirectUrl?: string) => dedent`
      ${serviceName} is on ✔️
      To modify your integration, visit https://app.productivity-ai.net for productivity tools and https://app.hints.so for CRMs
      ${
        redirectUrl
          ? `\n🔴 Finish your ${serviceName} integration setup here ${redirectUrl}\n`
          : ''
      }
    `,
});
