import { getApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { getRemoteConfig, getString } from 'firebase/remote-config';
import { useState } from 'react';

import { profileSelector, updateProfile } from '@hints/client';
import WhatsAppIcon from '@hints/shared/icons/integrations/WhatsApp.svg';

import { Button, LoaderIcon } from '../../../components';
import { QrCode } from '../../../components/QrCode';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { isMobile } from '../../../utils';
import { generateShortCode } from '../../../utils/generateShortCode';
import { openPopup } from '../../../utils/popup';
import { QrCodeConnect } from '../../Connect/components/QrCodeConnect';
import { usePopupConnect } from '../hooks/usePopupConnect';
import { InputIntegrationMetadata, IntegrationHook } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const DEFAULT_WHATSAPP_NUMBER = '+19412031424';

const useWhatsAppConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);
  const remoteConfig = getRemoteConfig(getApp());
  const whatsAppNumber =
    getString(remoteConfig, 'whatsAppNumber') || DEFAULT_WHATSAPP_NUMBER;

  const dispatch = useAppDispatch();
  const generateWhatsAppToken = async () => {
    const whatsAppToken = generateShortCode();
    await dispatch(updateProfile({ whatsAppToken }));
    return whatsAppToken;
  };

  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');
  const initialize = async () => {
    setToken(await generateWhatsAppToken());
  };

  const getWhatsAppQrCodeString = () => {
    const number = whatsAppNumber.startsWith('+')
      ? whatsAppNumber.slice(1)
      : whatsAppNumber;
    let url = `https://wa.me/${number}`;
    if (!user?.isWhatsAppConnected) {
      url += `?text=${encodeURIComponent(
        `/start ${token}${
          token ? ' \nSend this message to start Hints bot.' : ''
        }`,
      )}`;
    }
    return url;
  };

  const openWhatsApp = () => {
    const url = getWhatsAppQrCodeString();
    return openPopup(url, 'whatsapp', 800);
  };

  const isConnected = !!user.isWhatsAppConnected;
  const popupConnect = usePopupConnect(openWhatsApp, isConnected);
  const connect = async () => {
    await popupConnect();
    setIsLoading(true);
  };

  const disconnect = () =>
    dispatch(
      updateProfile({
        isWhatsAppConnected: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    initialize,
    isLoading,
    isConnected,
    connect: isMobile ? connect : () => {},
    disconnect,
    open: openWhatsApp,
    customButton: isMobile ? undefined : (
      <div className="mt-2 flex flex-col space-y-4">
        <Button
          text="Connect WhatsApp"
          leftContent={isLoading && <LoaderIcon />}
          disabled={isLoading}
          onClick={() => connect()}
        />

        <p className="text-gray-500">
          Or scan the QR code using the camera app on your phone. Don't use
          WhatsApp app to scan the QR code.
        </p>
        <QrCode data={getWhatsAppQrCodeString()} />
        <p className="flex pb-3 flex-row items-center justify-center space-x-2 text-sm text-gray-400 border-b border-b-neutral-200">
          <LoaderIcon />
          <span>Waiting for WhatsApp</span>
        </p>
      </div>
    ),
    customButtonV2: isMobile
      ? undefined
      : ({ metadata, back }) => (
          <QrCodeConnect
            metadata={metadata}
            qrCodeString={getWhatsAppQrCodeString()}
            connect={connect}
            back={back}
          >
            📲 To connect WhatsApp, click the “<b>Connect</b>” button below or
            scan the QR code with your camera to open the mobile version of
            WhatsApp app
          </QrCodeConnect>
        ),
  };
};

export const whatsapp: InputIntegrationMetadata = {
  title: 'WhatsApp',
  logoUrl: WhatsAppIcon,
  connectorHook: useWhatsAppConnector,
};
