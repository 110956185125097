import { createAsyncThunk } from '@reduxjs/toolkit';

import { Integration, IntegrationWithId } from '@hints/types';

import { uidSelector } from '../selectors';
import { RootState, ThunkExtra } from '../types';

export const createIntegration = createAsyncThunk<
  IntegrationWithId | undefined,
  Integration,
  {
    extra: ThunkExtra;
    state: RootState;
    rejectValue: undefined;
  }
>(
  `integrations/create`,
  async (data, { getState, extra: { getFirestore } }) => {
    const state = getState();
    const uid = uidSelector(state);
    const firestore = getFirestore();
    if (!uid) {
      return undefined;
    }

    // remove all undefined values
    const preparedData = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => v !== undefined),
    );

    try {
      const ref = await firestore.collection('integrations').add(preparedData);
      const snapshot = await ref.get();
      if (snapshot.exists) {
        return { ...snapshot.data(), id: snapshot.id } as IntegrationWithId;
      }
      return undefined;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  },
);

export const updateIntegration = createAsyncThunk<
  IntegrationWithId | undefined,
  Partial<IntegrationWithId>,
  {
    extra: ThunkExtra;
    state: RootState;
    rejectValue: undefined;
  }
>(
  `integrations/update`,
  async (data, { getState, extra: { getFirestore } }) => {
    const state = getState();
    const uid = uidSelector(state);
    const firestore = getFirestore();

    if (!uid) {
      return undefined;
    }

    try {
      const integrationRef = firestore.doc(`integrations/${data.id}`);
      if (!integrationRef) {
        return undefined;
      }

      // replace all undefined with firestore.FieldValue.delete()
      const preparedData = Object.fromEntries(
        Object.entries(data).map(([k, v]) =>
          v === undefined ? [k, firestore.FieldValue.delete()] : [k, v],
        ),
      );
      delete preparedData.id;

      await integrationRef.update(preparedData);

      const integrationSnapshot = await integrationRef.get();
      if (integrationSnapshot.exists) {
        return {
          ...integrationSnapshot.data(),
          id: integrationSnapshot.id,
        } as IntegrationWithId;
      }

      return undefined;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  },
);

export const removeIntegration = createAsyncThunk<
  IntegrationWithId | undefined,
  Partial<IntegrationWithId>,
  {
    extra: ThunkExtra;
    state: RootState;
    rejectValue: undefined;
  }
>(
  `integrations/remove`,
  async (data, { getState, extra: { getFirestore } }) => {
    const state = getState();
    const uid = uidSelector(state);
    const firestore = getFirestore();

    if (!uid) {
      return undefined;
    }

    try {
      const integrationRef = firestore.doc(`integrations/${data.id}`);
      if (!integrationRef) {
        return undefined;
      }

      const batch = firestore.batch();
      batch.delete(integrationRef);
      await batch.commit();

      return undefined;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  },
);

export const removeAllIntegrations = createAsyncThunk<
  undefined,
  Partial<IntegrationWithId>,
  {
    extra: ThunkExtra;
    state: RootState;
    rejectValue: undefined;
  }
>(
  `integrations/removeAll`,
  async (data, { getState, extra: { getFirestore } }) => {
    const state = getState();
    const uid = uidSelector(state);
    const firestore = getFirestore();
    if (!uid) {
      return undefined;
    }

    try {
      const integrations = await firestore
        .collection('integrations')
        .where('userId', '==', uid)
        .get();

      const batch = firestore.batch();
      // eslint-disable-next-line no-restricted-syntax
      for (const integration of integrations.docs) {
        batch.delete(integration.ref);
      }
      await batch.commit();

      return undefined;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  },
);
