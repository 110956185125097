import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import { CreditCardIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { classNames } from '../../utils/classNames';

const secondaryNavigation = [
  {
    name: 'General',
    href: '/settings',
    route: '/settings',
    icon: UserCircleIcon,
    current: true,
    disabled: false,
  },
  {
    name: 'Plan',
    href: 'subscription',
    route: '/settings/subscription',
    icon: CreditCardIcon,
    current: false,
    disabled: false,
  },
  // {
  //   name: 'Security',
  //   href: 'security',
  //   route: '/settings/security',
  //   icon: FingerPrintIcon,
  //   current: false,
  // },
];

const Settings = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 items-center gap-x-6">
            <button
              type="button"
              className="absolute top-4 left-4 flex space-x-2 cursor-pointer z-10 px-2.5 py-2 rounded bg-transparent hover:bg-gray-100 transition-all duration-300 ease-in-out"
              onClick={() => navigate('/')}
            >
              <ArrowLeftIcon className="w-5 h-5 text-gray-400" />
              <span className="text-gray-700 text-sm">Back Home</span>
            </button>
          </div>
        </div>
      </header>

      <div className="mx-auto max-w-7xl pt-16 lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={classNames(
                      location.pathname === item.route
                        ? 'bg-gray-50 text-indigo-600'
                        : item.disabled
                        ? 'text-gray-400 pointer-events-none'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold',
                    )}
                  >
                    <item.icon
                      className={classNames(
                        location.pathname === item.route
                          ? 'text-indigo-600'
                          : 'text-gray-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0',
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <Outlet />
        </main>
      </div>
    </>
  );
};

export default Settings;
