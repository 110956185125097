import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  WrenchScrewdriverIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useTour } from '@reactour/tour';
import { Fragment, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { profileSelector } from '@hints/client';

import logo from '../../assets/logo-circle.svg';
import { Intercom } from '../components';
import { useAppSelector } from '../hooks';
import { classNames } from '../utils/classNames';

const navigation = [
  {
    name: 'Integrations',
    href: '/',
    route: '/',
    icon: WrenchScrewdriverIcon,
    current: false,
    disabled: false,
    class: 'integrations',
  },
];

const Home = () => {
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const user = useAppSelector(profileSelector);
  const { currentStep, setCurrentStep } = useTour();

  return (
    <>
      <div className="w-full h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img className="h-8 w-auto" src={logo} alt="Hints" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <a
                                  href={item.href}
                                  className={classNames(
                                    location.pathname === item.route
                                      ? 'bg-gray-50 text-indigo-600'
                                      : item.disabled
                                      ? 'text-gray-400 hover:text-gray-400'
                                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    item.class,
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      location.pathname === item.route
                                        ? 'text-indigo-600'
                                        : 'text-gray-400 group-hover:text-indigo-600',
                                      'h-6 w-6 shrink-0',
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img className="h-8 w-auto" src={logo} alt="Hints" />
              <span className="font-semibold text-gray-800 ml-2">
                Hints. Productivity AI
              </span>
            </div>
            <nav className="flex flex-1 flex-col">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          onClick={() => setCurrentStep(currentStep + 1)}
                          className={classNames(
                            location.pathname === item.route
                              ? 'bg-gray-50 text-indigo-600'
                              : item.disabled
                              ? 'text-gray-400 hover:text-gray-400'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            item.class,
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                          )}
                        >
                          <item.icon
                            className={classNames(
                              location.pathname === item.route
                                ? 'text-indigo-600'
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0',
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <div className="mt-auto">
                  <li className="-mx-6 mt-2">
                    <Link
                      to="/settings"
                      className="settings flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                    >
                      <img
                        className="h-9 w-9 rounded-full bg-gray-50"
                        src={user?.photoUrl}
                        alt=""
                      />
                      <div className="flex flex-col">
                        <span className="sr-only">Your profile</span>
                        <span aria-hidden="true" className="-mb-1">
                          {user?.name || 'Hints user'}
                        </span>
                        <span
                          aria-hidden="true"
                          className="text-sm font-normal text-gray-500"
                        >
                          {user?.email || user?.id || 'Anonymous'}
                        </span>
                      </div>
                    </Link>
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
            Dashboard
          </div>
          <Link to="/settings" className="settings">
            <span className="sr-only">Your profile</span>
            <img
              className="h-9 w-9 rounded-full bg-gray-50"
              src={user?.photoUrl}
              alt=""
            />
          </Link>
        </div>

        <main className="py-4 lg:pl-72 h-full">
          <div className="px-4 sm:px-6 lg:px-0 w-full h-full">
            <Outlet />
          </div>
        </main>
      </div>
      <Intercom />
    </>
  );
};

export default Home;
