import { path } from 'ramda';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ChevronLeft } from '@hints/shared/icons/chevron-left-sq-fr.2.svg';

export const GoBackButton = ({
  routeToNavigate,
  className,
  isDisabled = false,
  fullWidth = false,
  label = 'Home',
}: {
  routeToNavigate?: string | number;
  className?: string;
  isDisabled?: boolean;
  fullWidth?: boolean;
  label?: string;
}) => {
  const navigate = useNavigate();

  const onBackPress = () => {
    if (isDisabled) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window?.ReactNativeWebView?.postMessage('goBack');
      } catch (e) {
        console.info(e);
      }
      return;
    }

    if (routeToNavigate) {
      navigate(routeToNavigate as string);
    } else if ((path(['history', 'state', 'idx'], window) as number) > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };

  return (
    <button
      type="button"
      className={`flex items-center select-none ${className}${
        isDisabled ? ' opacity-50 cursor-none pointer-events-none' : ''
      } ${
        fullWidth &&
        'w-full px-2 py-3 border-2 border-gray-100 rounded-full justify-center hover:bg-gray-50 transition duration-300 ease-in-out'
      }`}
      onClick={onBackPress}
    >
      <ChevronLeft width={20} height={20} />
      <span className="ml-1 text-sm font-semibold text-gray-800">{label}</span>
    </button>
  );
};

export default GoBackButton;
