import { useEffect, useState } from 'react';

export function usePopupConnect(
  popupFunction: () => Promise<Window | null> | Window | null,
  isConnected: boolean,
) {
  const [popup, setPopup] = useState<Window | null>(null);
  const connect = async () => {
    const window = await popupFunction();
    setPopup(window);
  };

  useEffect(() => {
    if (isConnected && popup) {
      popup.close();
    }
  }, [isConnected, popup]);

  return connect;
}
