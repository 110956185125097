import dedent from 'dedent-js';

import { OnboardingResponse } from '../types';

const response: OnboardingResponse = {
  getBotOnboardingText: (shortUrl?: string) => {
    if (shortUrl) {
      return dedent`
        Connect your HubSpot account to get started. 🚀
        ${shortUrl}
      `;
    }
    return dedent`
      Hubspot ✔️
      Try:
      "Create a new contact named John Smith and link a new company, IBM."
      "Add a task to the John Smith to send proposal by tomorrow 2 PM."
    `;
  },
};

export default response;
