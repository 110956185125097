import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { isLoaded } from 'react-redux-firebase';

import { integrationsArraySelector } from '@hints/client';
import { BaseIntegration } from '@hints/types';

import { useAppSelector } from '../../../hooks';
import { DraftIntegration } from '../types';

export const useIntegration = (
  id: string | undefined,
  data: BaseIntegration,
): [DraftIntegration | null, Dispatch<SetStateAction<DraftIntegration>>] => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [integration, setIntegration] = useState<DraftIntegration | null>(null);

  const userIntegrations = useAppSelector(integrationsArraySelector);
  const existingIntegration = userIntegrations.find((i) => i.id === id);

  useEffect(() => {
    if (isLoaded(userIntegrations) && !isInitialized) {
      if (existingIntegration) {
        setIntegration(existingIntegration);
      } else {
        setIntegration(data);
      }
      setIsInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIntegrations]);

  return [
    integration,
    setIntegration as Dispatch<SetStateAction<DraftIntegration>>,
  ];
};
