import firebase from 'firebase/compat/app';

import { profileSelector, updateProfile } from '@hints/client';
import ClickupIcon from '@hints/shared/icons/integrations/ClickUp.svg';

import ClickupTutorialGif from '../../../../assets/TG-ClickUP.png';
import ClickupStep2 from '../../../../assets/instructions/clickup/step-2.png';
import ClickupStep3 from '../../../../assets/instructions/clickup/step-3.png';
import {
  useAppDispatch,
  useAppSelector,
  useHttpsCallable,
} from '../../../hooks';
import { useOAuth2AuthorizationCodeGrantPopup } from '../hooks/useOAuth2AuthorizationCodeGrantPopup';
import { ClickupSetup } from '../setups/ClickupSetup';
import { IntegrationHook, OutputIntegrationMetadata } from '../types';

import FieldValue = firebase.firestore.FieldValue;

const CLIENT_ID = 'BIPOFHPSGAOTUK71G58A6OF1VFSJJ3UI';
const AUTH_URL = `https://app.clickup.com/api?client_id=${CLIENT_ID}`;

const useClickupConnector: IntegrationHook = () => {
  const user = useAppSelector(profileSelector);

  const dispatch = useAppDispatch();

  const [authorizeClickup] = useHttpsCallable('authorizeClickup');
  const [startAuth, isLoading, error] = useOAuth2AuthorizationCodeGrantPopup({
    authUrl: AUTH_URL,
    onComplete: async (code, redirectUri) => {
      await authorizeClickup({ code, redirectUri });
    },
  });

  const disconnect = () =>
    dispatch(
      updateProfile({
        clickup: FieldValue.delete() as unknown as undefined,
      }),
    );

  return {
    isConnected: !!user.clickup?.accessToken,
    connect: startAuth,
    isLoading,
    disconnect,
    error,
  };
};

export const clickup: OutputIntegrationMetadata<'clickup'> = {
  title: 'ClickUp',
  logoUrl: ClickupIcon,
  connectorHook: useClickupConnector,
  defaultTagName: 'clickup',
  setupComponent: ClickupSetup,
  tutorial: {
    gif: (
      <img
        src={ClickupTutorialGif}
        alt=""
        className="w-full h-[160px] rounded-lg"
      />
    ),
    examples: [
      '✅ To create a task, simply send the Assistant your text',
      '📝 To send your comment to the task, reply to the original message with the task',
      '👥 To use this bot with your team, add it to your group and use #clickup in your requests. Example: Launch a new website #clickup',
    ],
  },
  instructions: (
    <div className="space-y-8">
      <div>
        <b>Step 1.</b> Click the “<b>Login to ClickUp</b>” button below
      </div>
      <div>
        <span>
          <b>Step 2.</b> Choose your account in ClickUp popup window
        </span>
        <img
          src={ClickupStep2}
          alt="step2"
          className="max-h-[270px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
      <div>
        <span>
          <b>Step 3.</b> Give access to a workspace{' '}
          <i>(click on the circle workspace logo)</i>
        </span>
        <img
          src={ClickupStep3}
          alt="step3"
          className="max-h-[270px] middle:max-h-[360px] mt-2 rounded-lg"
        />
      </div>
    </div>
  ),
};
