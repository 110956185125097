const givenSet = 'abcdefghijkmnpqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ23456789';

export const generateShortCode = (length = 12) => {
  let code = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < length; i++) {
    const pos = Math.floor(Math.random() * givenSet.length);
    code += givenSet[pos];
  }
  return code;
};
