import { useEffect } from 'react';

import { useQueryParam } from '../hooks/useQueryParam';
import { getUrlWithQueryParams } from '../utils/getUrlWithQueryParams';

export const Redirect = () => {
  const url = useQueryParam('url') as string;
  const redirectUri = useQueryParam('redirect_uri') as string;

  useEffect(() => {
    setTimeout(() => {
      window.open(
        getUrlWithQueryParams(url, { redirect_uri: redirectUri }),
        '_self',
      );
    }, 1000);
  }, []);

  return null;
};

export default Redirect;
